<template>
    <div>
        <b-navbar toggleable="xl" type="custom" style="height: 100px; padding: 0px 20px;">
            <div class="container d-flex justify-content-center">
            
                <!-- <img src="/media/logo.png" class="logo" /> -->
                <div class="logo">
                <div class="logo-text">Blacktie</div>
                <div class="sub-logo-text">Collaborative</div>
                <div class="site-assessment-logo">SITE ASSESSMENT</div>
                </div>

            </div>
        </b-navbar>
      <!-- <Navigation></Navigation> -->
    </div>
</template>

<script>
// import Navigation from "./Navigation.vue";
  export default {
    name: "Header",
    // components: { Navigation },
    data() {
      return {
        // Add a data property for the current year
      };
    },
  };
</script>