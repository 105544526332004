<template>
    <section
      class="py-7 py-xl-9 position-relative overflow-hidden bg-cover"
      style="background-image: url('/media/bg-2.jpg')"
    >
      <div class="container">
        <h2
          class="display-4 fw-bold text-center text-gradient-primary text-uppercase mb-7 mb-xl-9"
        >
          Love from our clients
        </h2>
      </div>
      <div class="container-fluid px-md-0">
        <flickity
          class="flickity"
          :options="flickityOptions"
        >
          <div class="col-12 col-md-8 col-lg-6" v-for="(testimonial, index) in testimonials" :key="index">
            <div class="card text-white bg-white-10">
              <div class="card-body d-flex flex-column p-xl-7">
                <div class="d-flex align-items-center mb-3">
                  <div class="mr-4">
                    <font-awesome-icon icon="star" class="text-warning" v-for="n in 5" :key="n"/>
                  </div>
                  <small>5.0 Stars</small>
                </div>
                <p class="mb-4" v-for="(paragraph, pIndex) in testimonial.paragraphs" :key="pIndex">
                  {{ paragraph }}
                </p>
                <div class="mt-auto row no-gutters">
                  <div class="col-12 col-sm">
                    <h5 class="mb-0 text-uppercase fw-bold">{{ testimonial.author }},</h5>
                    <small class="text-uppercase">{{ testimonial.position }}</small>
                  </div>
                  <div class="col-12 col-sm-auto text-right">
                    <img
                      src="/media/quote.png"
                      class="of-contain"
                      width="50"
                      height="50"
                      alt="Testimonial from a satisfied web design client"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </flickity>
      </div>
      <div
        class="col-2 d-none d-sm-block bg-gradient-primary position-absolute top-0 left-0 skew-x-n45 ml-n7"
        style="height: 100px;"
      ></div>
    </section>
</template>

<script>
export default {
  name: "LoveFromOurClients",
  data() {
    return {
        flickityOptions: {
          pageDots: true,
          prevNextButtons: false,
          freeScroll: false,
          wrapAround: true,
          autoPlay: 5000,
        },
        testimonials: [
            {
                paragraphs: [
                    "Partnering with Blacktie Toronto was a game-changer for us. They supercharged our operations with their fast-paced development and innovative solutions. Thanks to their expertise, we've not only doubled our business in two years but are on track to triple it.",
                    "Their fearless honesty and radical transparency have reshaped our company culture, making us smarter and more efficient than ever."
                ],
                author: "COREY COTTRELL",
                position: "CO-OWNER, SHOWZONE.GG"
            },
            {
                paragraphs: [
                "Blacktie is not the first development company I have worked with, but certainly the most knowledgeable and accommodating.",
                "In my experience working with other developers, the one difference that stands out most about Blacktie is foresight. Other developers have always been quick to do exactly what I ask, without any thought into how it will affect my business in the future. Blacktie truly made every effort to understand not just my requests, but also my goals. I'd recommend them to anyone looking to build a business who truly cares about expansion and scalability."
                ],
                author: "JAMIE WATERS",
                position: "PRODUCT MANAGER, A & C GAMES"
            },
            {
                paragraphs: [
                "The team at Steel City Inclusive Softball Association is tremendously pleased with the work done by Blacktie Collaborative on our website and online store. The professionalism has instilled much confidence and we always get immediate responses to our requests for updates/changes.",
                "The team at Blacktie helped guide us in the development of our business needs and provided invaluable assistance in design and ideas to help enhance the user experience of our site. I would/have highly recommended this group to others and can not emphasize our satisfaction enough."
                ],
                author: "BLAINE PERRY",
                position: "FOUNDER, STEEL CITY INCLUSIVE SOFTBALL ASSOCIATION"
            },
            {
                paragraphs: [
                "The team at Blacktie are top notch professionals and really know what they are doing. They allowed me my own custom creative freedom and at the same time they had their own creative standards so it was a double win win for me. It was a painless process, super fun, and interactive. I'd recommend them to anyone looking for cutting edge technology needs and wanting to work with a personalized custom firm."
                ],
                author: "CASEY VAN ZANDT",
                position: "LIFESTYLE COACH, CASEY VAN ZANDT"
            },
            {
                paragraphs: [
                "Blacktie has been incredible to work with. Not only has Blacktie been incredibly responsive, productive, and efficient, but they were able to visualize what we wanted done and make it come to life. I would absolutely recommend Blacktie to anyone looking to make an impact online. I truly admire and appreciate the commitment Blacktie brought to every project that they built for us."
                ],
                author: "KYLE HODGES",
                position: "OWNER, GAMEONMOBILE.COM"
            },
            {
                paragraphs: [
                "We met with the Blacktie Team and gave them an extremely ambitious project to complete with an impossible deadline. I did not think it could be accomplished. But instead, the product they provided and turnaround time were beyond our expectations! When we hit a challenge, they were always able to find a solution. In addition to the clean and crisp look to our website, I was most impressed with the excellent client service they provided. I would not hesitate to recommend their services."
                ],
                author: "SHAWN LYTTON",
                position: "NIKE SPORTS CAMPS"
            },
            {
                paragraphs: [
                "Working with Blacktie has been an incredible experience! I am so pleased with my decision to work with them and it has made my life so much easier already! I already have more traffic, love my website and have the support I was lacking before! Their developers are very talented, responsive, and creative. My traffic has already increased and I am launching a Meditation course soon that I am confident will bring in increased revenues with the technology Blacktie created!"
                ],
                author: "BRITT DEANDA",
                position: "FOUNDER, ELEVATE THE GLOBE"
            }
            ]
    };
    
  },
  created() {
    this.flickityOptions.initialIndex = this.getRandomIndex();
  },
  methods: {
    getRandomIndex() {
      return Math.floor(Math.random() * this.testimonials.length);
    }
  }
  
}
</script>