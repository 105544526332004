<template>
  <span>
    <iframe :data-tally-src="`https://tally.so/embed/${embedUrl}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`" loading="lazy" width="100%" height="768" frameborder="0" marginheight="0" marginwidth="0" title="Contact Us"></iframe>
  </span>
</template>

<script>
/* global Tally */
// import ContactForm from "./ContactForm.vue";
export default {
  name: "ContactForm",
  props: {
    embedUrl: {
      type: String,
      default: "wAdEql"
    }
  },
  components: {  },
  mounted() {
    let script = document.createElement('script');
    script.src = "https://tally.so/widgets/embed.js";
    script.onload = this.loadTallyEmbeds;
    script.onerror = this.loadTallyEmbeds;
    document.body.appendChild(script);
  },
  methods: {
    loadTallyEmbeds() {
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds();
      } else {
        document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframe) => {
          iframe.src = iframe.dataset.tallySrc;
        });
      }
    }
  }
};
</script>
<style scoped>
.error {
  color: red;
}
</style>
