<template>
  <div>
    <section
      class="py-7 py-xl-9 bg-cover"
      style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/images/bg-534.png');"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1
              class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
            >
              We'd love to<br class="d-none d-xl-block" /> speak with you 
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 py-xl-9 bg-white">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-7 col-xl-6 col-xxl-5">
            <div>
              <h2
                class="display-4 text-center fw-bold text-gradient-primary text-uppercase mb-4"
              >
                Email Us
              </h2>
              <p class="text-secondary text-center mb-4">
                
              </p>
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ContactForm from "../components/ContactForm.vue";
export default {
  name: "ContactPage",
  components: { ContactForm },

  created: function() {
    setTimeout(() => {
      var customPill = document.querySelector(".nav-custom-pills");
      var customPillDimension = customPill.getBoundingClientRect();
      var activePillLink = customPill.querySelector(".nav-link.active");
      var activePillLinkDimension = activePillLink.getBoundingClientRect();
      var activePillLinkWidth = activePillLinkDimension.width;

      var activePillLinkTranslate =
        activePillLinkDimension.left - customPillDimension.left;

      customPill.style.setProperty("--pill-width", activePillLinkWidth + "px");
      customPill.style.setProperty(
        "--pill-translate",
        activePillLinkTranslate + "px"
      );
      [].forEach.call(
        document.querySelectorAll(".nav-custom-pills .nav-link"),
        function(el) {
          el.addEventListener("click", function() {
            var customPill = document.querySelector(".nav-custom-pills");
            var customPillDimension = customPill.getBoundingClientRect();
            var activePillLink = customPill.querySelector(".nav-link.active");
            var activePillLinkDimension = activePillLink.getBoundingClientRect();
            var activePillLinkWidth = activePillLinkDimension.width;

            var activePillLinkTranslate =
              activePillLinkDimension.left - customPillDimension.left;

            customPill.style.setProperty(
              "--pill-width",
              activePillLinkWidth + "px"
            );
            customPill.style.setProperty(
              "--pill-translate",
              activePillLinkTranslate + "px"
            );
          });
        }
      );
    }, 100);
  },
};
</script>
