<template>
    <div>
      <section
        class="py-7 py-xl-9 bg-cover"
        style="background-image: url('/media/bg-5.jpg');"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                Growth through Education
              </p>
              <h1
                class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Our Blog
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div v-if="loading" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <section class="py-4 py-xl-5 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-md-6 mb-4" v-for="post in posts" :key="post.id">
              <div class="card h-100">
                <img 
                  v-if="post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]" 
                  :src="post._embedded['wp:featuredmedia'][0].source_url" 
                  class="card-img-top" 
                  alt="Featured Image"
                />
                <div class="card-body">
                  <h4 class="h5 fw-bold text-gradient-primary text-uppercase mb-3">
                    {{ post.title.rendered }}
                  </h4>
                  <p class="card-text" v-html="post.excerpt.rendered"></p>
                  <router-link :to="`/blog/${post.slug}`" class="btn btn-gradient-primary mt-3">Read More</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage === 1 }" v-if="currentPage > 1">
            <a class="page-link" href="#" @click.prevent="fetchPosts(currentPage - 1)">Previous</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="fetchPosts(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }" v-if="currentPage < totalPages">
            <a class="page-link" href="#" @click.prevent="fetchPosts(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>
      <ConnectWithUs></ConnectWithUs>
      <OurLocation></OurLocation>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import ConnectWithUs from '../components/ConnectWithUs.vue';
  import OurLocation from '../components/OurLocation.vue';
  
  export default {
    name: "BlogPage",
    data() {
      return {
        posts: [],
        currentPage: 1,
        totalPages: 0,
        loading: false, 
      };
    },
    components: {
        ConnectWithUs,
        OurLocation
    },
    mounted() {
      this.fetchPosts(this.currentPage);
    },
    methods: {
        async fetchPosts(page) {
            this.loading = true; // Start loading
            const response = await axios.get(`https://wii.dnw.mybluehost.me/website_c0adc160/wp-json/wp/v2/posts?page=${page}&_embed`);
            console.log('response', response.data, response.headers['x-wp-totalpages']);
            this.posts = response.data;
            this.totalPages = parseInt(response.headers['x-wp-totalpages']);
            this.currentPage = page;
            this.loading = false; // End loading
        },
    },
  };
  </script>
  
  <style scoped>
  
  </style>