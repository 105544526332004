<template>
    <section class="py-7 py-xl-9 bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img src="https://teamgear.ca/cdn/shop/files/isabel-pineiro-MJFBzRgEqEc-unsplash.jpg?v=1657573618&width=535" alt="Hamilton, Ontario" class="img-fluid rounded shadow-sm">
          </div>
          <div class="col-md-6">
            <h2 class="display-4 fw-bold text-gradient-primary text-uppercase mb-4">
              Proudly Canadian
            </h2>
            <p class="lead mb-4">
              Our main location is in the heart of Hamilton, Ontario. As a proudly Canadian company, we bring the warmth 
              of our local community and the innovation of the Great White North to every project we undertake.
            </p>
            <p>
              From the steel city to the digital frontier, we embody the resilience and creativity that Hamilton and Canada 
              are known for. Our roots in this vibrant city inspire us to deliver web solutions that are as robust and 
              diverse as the Canadian landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'OurLocation'
  }
  </script>
  
  <style scoped>
  .text-gradient-primary {
    background-image: linear-gradient(45deg, #FFD700, #FFA500);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
  }
  </style>