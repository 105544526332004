<template>
  <div>
    <section
      class="py-7 py-xl-9 bg-cover"
      style="background-image: url('/media/bg-4.jpg');"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
              We've worked with
            </p>
            <h1
              class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              online teachers, spiritual coaches / guides and lifestyle brands
              since day one.
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="pt-7 pt-xl-9">
      <img src="/media/img-5.png" class="img-fluid mb-4" />
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 col-xl-9 text-center">
            <p class="lead text-secondary mb-7 mb-xl-9">
              Our 7+ years of involvement in this industry, including the trials
              and tribulations, along with our learning experiences all get
              passed down to you on day one of our build. We've built out a
              system that helps you get your online courses, lessons, and other
              forms of teaching engagement quickly and simply. In short -
              <strong class="text-black"
                >we understand your industry, and with that understanding,
                you're getting a premium, unmatched service.</strong
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row no-gutters justify-content-end overflow-hidden">
        <div
          class="col-8 bg-gradient-primary skew-x-n45 mr-n7"
          style="height: 100px;"
        ></div>
      </div>
    </section>

    <section class="py-7 py-xl-9 bg-black text-gray-400">
      <div class="container">
        <div class="row align-items-center gy-7">
          <div class="col-12 col-lg-6" data-aos="fade-right">
            <img src="/media/photo-5.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-left">
            <h1 class="fw-bold text-gradient-primary text-uppercase mb-7">
              What makes our lifestyle websites special?
            </h1>
            <p>
              <span class="text-primary"
                >Working in the lifestyle industry for 7 years,</span
              >
              We've learned what works and what doesn't. At the end of the day,
              converting potential customers into students of your brand is top
              priority - they are the fuel that both powers your passion for
              teaching, as well as keeps your brand growing financially.
            </p>
            <hr class="border-secondary my-4" />
            <p>
              <span class="text-primary"
                >We know what it's like to fail in this industry</span
              >
              - usually failures are scary, and something most companies won't
              admit to. We admit to them because we know they've helped us
              provide better products. We learn from both our mistakes and our
              successes, and as a result we are constantly striving for
              perfection.
            </p>
            <hr class="border-secondary my-4" />
            <p class="mb-0">
              We know this industry isn't one size fits all. In fact,
              <span class="text-primary"
                >we believe the lifestyle industry is the most prominent example
                of "one size DOESN'T fit all."</span
              >
              What allows our teachers to grow, is that their platforms are all
              very unique and different - because it fits to their niche
              consumer base. Since we understand their niche, and they
              understand their customer, we combine our knowledge to build
              beautiful products.
            </p>
          </div>
        </div>
      </div>
    </section>
    <OurServices :niche="'lifestyle'"></OurServices>
    <WhyWorkWithUs></WhyWorkWithUs>
    <LoveFromOurClients></LoveFromOurClients>

    <section
      class="bg-auto-90 bg-white"
      style="background-image: url('/media/bg-3.png')"
    >
      <div class="row no-gutters overflow-hidden mb-7 mb-xl-0">
        <div
          class="col-6 col-lg-3 pe-none bg-gradient-primary skew-x-n45 ml-n7"
          style="height: 100px;"
        ></div>
      </div>
      <div class="container">
        <h2
          class="display-4 fw-bold text-center text-gradient-primary text-uppercase mb-7 mb-xl-9"
        >
          Why does this matter?
        </h2>
        <div class="row align-items-center justify-content-between gy-7 mb-7">
          <div class="col-12 col-lg-6 order-lg-2" data-aos="fade-left">
            <img src="/media/photo-6.jpg" class="img-fluid" />
          </div>
          <div
            class="col-12 col-lg-6 col-xxl-5 order-lg-1"
            data-aos="fade-right"
          >
            <p class="text-secondary mb-3">
              Without getting into a ton of specific examples,<strong
                class="text-black"
              >
                we're able to work with exactly how your customer wants to
                consume your product.</strong
              >
              We’ve worked with clients that use monthly subscriptions, custom
              payment plans (eg. 6 easy payments of $), pay in full courses as
              well as with upsell and downsell funnels.
            </p>
            <p class="text-secondary mb-3">
              Some of our clients take the "Netflix approach" - a membership
              based, open area where the customer can browse and consume your
              teachings as they desire, when they desire. Some of our clients
              take a more course/lesson approach, where the customer pays X
              dollars and gets access to a course and for example, 8 lessons.
              Some like to drip their content - perhaps one new lesson a week -
              some like to offer all the content upfront and let the customer
              work through it at their own pace.
            </p>
            <p class="text-secondary mb-0">
              <strong class="text-black"
                >Because we're flexible, you get to be flexible.</strong
              >
              And because you're flexible - your customer is much more likely to
              buy into your product. And once they're in your ecosystem, we've
              got a bevy of tools that will keep them coming back and consuming
              more and more of your content.
            </p>
          </div>
        </div>
      </div>
      <div class="row no-gutters justify-content-end overflow-hidden">
        <div
          class="col-6 col-lg-3 pe-none bg-gradient-primary skew-x-n45 mr-n7"
          style="height: 100px;"
        ></div>
      </div>
    </section>

    <section class="py-7 py-xl-9">
      <div class="container">
        <p class="lead text-uppercase mb-0 text-black">Case study</p>
        <h2 class="display-4 fw-bold text-gradient-primary text-uppercase mb-0">
          Elevate The Globe
        </h2>
        <p class="text-secondary mb-4">
          Connecting with your inner self for happiness, joy and mental clarity.
        </p>
        <div class="row gy-4 mb-4">
          <div class="col-12 col-lg-6">
            <img src="/media/photo-7.jpg" class="img-fluid" />
          </div>
          <div class="col-12 col-lg-6">
            <h4 class="fw-bold mb-2">
              A Stagnant Brand
            </h4>
            <p class="text-secondary mb-4">
              When Elevate The Globe came to us 8 years ago, they decided it was time to get serious about their business. At the time, they did not have anything compared to what they have today. A basic Squarespace website,
              where they manually had to charge customers, and were limited at a very low ceiling for growth. 
            </p>
            <h4 class="fw-bold mb-2">
              A Superpower In Their Industry
            </h4>
            <p class="text-secondary mb-0">
              Throughout the last 8 years, we have directly helped Elevate The Globe (ETG) grow their brand and their platform. From 20 active users, to over 20k active users, Elevate The Globe has grown into a multi-course
              system that serves people through their various teachings and helps people grow into their true self. Their app, 528 Academy, which was created in conjunction with our consulting team, has brought in memberships
              of well over 400 people per month, giving Elevate The Globe a landing platform for their financials and allowed them to become more aggressive with their pay to own products. Overall, ETG has grown into a 200k per
              year company with 7 employees, pumping out content year round with over 150 million watched minutes on their material.
            </p>
          </div>
        </div>
      
      </div>
    </section>
    <ConnectWithUs></ConnectWithUs>
    <OurLocation></OurLocation>
  </div>
</template>

<script>
import ConnectWithUs from "../components/ConnectWithUs.vue";
import LoveFromOurClients from "../components/LoveFromOurClients.vue";
import OurServices from '../components/OurServices.vue';
import WhyWorkWithUs from '../components/WhyWorkWithUs.vue';
import OurLocation from '../components/OurLocation.vue';

export default {
  name: "LifestylePage",
  components: { ConnectWithUs, LoveFromOurClients, OurServices, WhyWorkWithUs, OurLocation },
};
</script>
