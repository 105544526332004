<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/seogrowth.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            We help you build the foundation that holds your house up forever
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Why Choose BlacktieCollab?
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where our SEO growth strategies are designed to elevate your online presence and connect your brand with your target audience. Specializing in comprehensive SEO services, we ensure that your website not only ranks higher in search engine results but also achieves sustainable growth in traffic and conversions.</p>

                        <p>At Blacktie Collaborative, we understand that effective SEO is not just about keywords and backlinks—it’s about creating a holistic online strategy that resonates with your brand identity and market needs. We dive deep into your business goals to tailor a unique SEO plan that includes on-page optimization, content marketing, and technical SEO, ensuring your site is visible, relevant, and authoritative.</p>

                        <p>Move beyond the basics with Blacktie Collaborative’s advanced SEO techniques. We leverage cutting-edge tools and data-driven insights to optimize your website’s structure, speed, and content for the best user experience. Our focus on strategic keyword placement, meta-data enhancements, and quality link-building campaigns distinguishes your site in competitive industries.</p>

                        <p>Whether you're based in <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> or any other city, our SEO services extend to your area. Blacktie Collaborative's dedicated team of SEO experts ensures that your online efforts are not just seen, but felt across your industry, driving meaningful engagement and customer acquisition.</p>

                        <p>Understanding the importance of mobile optimization in today’s digital landscape, we ensure your website is fully responsive and optimized for mobile devices. This not only improves user experience but also boosts your SEO rankings as search engines favor mobile-friendly sites.</p>

                        <p>Need to boost your visibility in e-commerce? Blacktie Collaborative offers specialized SEO strategies for e-commerce platforms, focusing on optimizing product descriptions, images, and user reviews to enhance search visibility and buyer interaction. Our strategies are designed to attract more traffic and convert browsers into buyers.</p>

                        <p>Ready to see your website climb the ranks? Reach out to Blacktie Collaborative today. No matter where you are located, from <span v-if="city">{{ cityDisplayName }}</span> to any other region, our SEO experts are equipped to propel your digital success. Click the <strong>Contact Us</strong> button below to begin your journey to the top of the search results.</p>

                    </div>
                </div>
            </div>
        </section>
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>

        
        <section class="py-7 py-xl-9 bg-light">
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7">
                Case Study: TeamGear.ca
                </h2>
                <div class="row align-items-center">
                <div class="col-12 col-lg-6 mb-5 mb-lg-0">
                    <img src="https://cdn.blacktiecollab.com/blacktiecollab.com/brands/teamgear-ca/CleanShot 2024-04-17 at 17.12.29.png" alt="TeamGear.ca Website" class="img-fluid rounded shadow-lg">
                </div>
                <div class="col-12 col-lg-6">
                    <h3 class="h2 mb-4">Revolutionizing E-commerce SEO</h3>
                    <p class="lead mb-4">We transformed TeamGear.ca's online presence with our SEO-driven Shopify store development. Our strategies generated consistent traffic and conversions, resulting in over $100k in revenue within the first year.</p>
                    <ul class="list-unstyled mb-5">
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Custom Shopify Theme Optimization</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>SEO-Focused Content Strategy</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Conversion-Oriented Page Layouts</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Automated Lead Generation</li>
                    </ul>
                    <a href="/brand/teamgear-ca" class="btn btn-primary btn-lg">View Full Case Study</a>
                </div>
                </div>
            </div>
        </section>
        <ConnectWithUs></ConnectWithUs>
        <Niches></Niches>
        <OurLocation></OurLocation>
    </div>
</template>


<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import Niches from '../components/Niches.vue'
import OurLocation from '../components/OurLocation.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'

export default {
  name: 'SeoGrowthPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, Niches, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
      workWithUsBoxes: [
        // Add your workWithUsBoxes data here
      ]
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'SEO Services';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/seo-services';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative offers comprehensive ${this.pageTitle.toLowerCase()} to elevate your online presence and drive sustainable growth. Our expert team tailors unique strategies combining on-page optimization, content marketing, and technical SEO to improve your search rankings, increase traffic, and boost conversions across all devices and platforms.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative offers comprehensive ${this.pageTitle.toLowerCase()} to elevate your online presence and drive sustainable growth. Our expert team tailors unique strategies combining on-page optimization, content marketing, and technical SEO to improve your search rankings, increase traffic, and boost conversions across all devices and platforms.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>