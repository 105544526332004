<template>
  <div>
    <main>
      <!-- Only show Header if not on /site-assessment or any of its dynamic subpaths -->
      <Header v-if="!$route.path.startsWith('/site-assessment')"></Header>
      <SiteAssessmentHeader v-else></SiteAssessmentHeader>

      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>

      <!-- Only show Footer if not on /site-assessment or any of its dynamic subpaths -->
      <Footer v-if="!$route.path.startsWith('/site-assessment')"></Footer>

    </main>
  </div>
</template>

<script>
import Header from './components/globals/Header.vue'
import SiteAssessmentHeader from './components/globals/SiteAssessmentHeader.vue';
import Footer from './components/globals/Footer.vue'
export default {
  name: "App",
  components: { Footer, Header, SiteAssessmentHeader },
  data() {
    return {
      // Add a data property for the current year
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
/* Define the fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.dropdown-item {
   /* Change the text color */
  background-color: #f5f5f5; /* Change the background color */
  font-weight: 700 !important;
  text-transform: uppercase;
}

/* If you need to use deep selectors for scoped styles */
.nav-link:hover {
  color: #fff !important;
  background: #000;
  transition: all 0.3s;
}
</style>