export const validNiches = {
    'sports': 'Sports',
    'lifestyle': 'Lifestyle',
    'automotive': 'Automotive',
    'local': 'Local',
    'non-profit': 'Non-Profit',
    'local-non-profit': 'Local Non-Profit',
    'restaurant': 'Restaurant',
    'local-restaurant': 'Local Restaurant',
    'local-automotive': 'Local Automotive',
    'local-sports': 'Local Sports',
    'local-lifestyle': 'Local Lifestyle',
    'cafe': 'Cafe',
    'local-cafe': 'Local Cafe',
    'bilingual': 'Bilingual',
    'cultural': 'Cultural',
    'local-cultural': 'Local Cultural',
    'festivals': 'Festivals',
    'local-festivals': 'Local Festivals',
    'cultural-festivals': 'Cultural Festivals',
    'local-cultural-festivals': 'Local Cultural Festivals',
    'historical': 'Historical',
    'local-historical': 'Local Historical',
    'educational': 'Educational',
    'local-educational': 'Local Educational',
    'vending': 'Vending',
    'local-vending': 'Local Vending',
    'vending-machine': 'Vending Machine',
    'local-vending-machine': 'Local Vending Machine',
    'vending-machine-repair': 'Vending Machine Repair',
    'real-estate': 'Real Estate',
    'local-real-estate': 'Local Real Estate',
    'accountant': 'Accountant',
    'local-accountant': 'Local Accountant',
    'lawyer': 'Lawyer',
    'local-lawyer': 'Local Lawyer',
    'local-attorney': 'Local Attorney',
    'attorney': 'Attorney',
    'local-legal': 'Local Legal',
    'legal': 'Legal',
    'local-legal-services': 'Local Legal Services',
    'plumber': 'Plumber',
    'local-plumber': 'Local Plumber',
    'electrician': 'Electrician',
    'local-electrician': 'Local Electrician',
    'handyman': 'Handyman',
    'local-handyman': 'Local Handyman',
    'local-home-services': 'Local Home Services',
    'home-services': 'Home Services',
    'local-home-improvement': 'Local Home Improvement',
    'home-improvement': 'Home Improvement',
    'side-hustle': 'Side Hustle',
    'local-side-hustle': 'Local Side Hustle',
    'local-business': 'Local Business',
    'business': 'Business',
    'seller': 'Seller',
    'local-seller': 'Local Seller',
    'local-ecommerce': 'Local Ecommerce',
    'ecommerce': 'Ecommerce',
    'resorts': 'Resorts',
    'local-resorts': 'Local Resorts',
    'local-hotel': 'Local Hotel',
    'hotel': 'Hotel',
    'local-travel': 'Local Travel',
    'travel': 'Travel',
    'local-tourism': 'Local Tourism',
    'tourism': 'Tourism',
    'local-entertainment': 'Local Entertainment',
    'entertainment': 'Entertainment',
    'local-entertainment-venue': 'Local Entertainment Venue',
    'entertainment-venue': 'Entertainment Venue',
    'local-entertainment-services': 'Local Entertainment Services',
    'entertainment-services': 'Entertainment Services',
    'cannabis': 'Cannabis',
    'local-cannabis': 'Local Cannabis',
    'local-cannabis-dispensary': 'Local Cannabis Dispensary',
    'cannabis-dispensary': 'Cannabis Dispensary',
    'local-cannabis-grower': 'Local Cannabis Grower',
    'cannabis-grower': 'Cannabis Grower',
    'sports-venue': 'Sports Venue',
    'local-sports-venue': 'Local Sports Venue',
    'healthcare': 'Healthcare',
    'office-setup': 'Office Setup',
    'junk-removal': 'Junk Removal',
    'moving': 'Moving',
    'cleaning': 'Cleaning',
    'local-cleaning': 'Local Cleaning',
    'local-moving': 'Local Moving',
    'local-junk-removal': 'Local Junk Removal',
    'grass-cutting': 'Grass Cutting',
    'local-grass-cutting': 'Local Grass Cutting',
    'local-landscaping': 'Local Landscaping',
    'landscaping': 'Landscaping',
    'local-lawn-care': 'Local Lawn Care',
    'lawn-care': 'Lawn Care',
    'local-pest-control': 'Local Pest Control',
    'pest-control': 'Pest Control',
    'local-pool-services': 'Local Pool Services',
    'pool-services': 'Pool Services',
    'local-roofing': 'Local Roofing',
    'roofing': 'Roofing',
    'local-snow-removal': 'Local Snow Removal',
    'snow-removal': 'Snow Removal',
    'local-tree-services': 'Local Tree Services',
    'tree-services': 'Tree Services',
    'local-utilities': 'Local Utilities',
    'utilities': 'Utilities',
    'hair-salon': 'Hair Salon',
    'local-hair-salon': 'Local Hair Salon',
    'local-beauty-salon': 'Local Beauty Salon',
    'beauty-salon': 'Beauty Salon',
    'local-nail-salon': 'Local Nail Salon',
    'nail-salon': 'Nail Salon',
    'local-barber': 'Local Barber',
    'barber': 'Barber',
    'local-tattoo-parlor': 'Local Tattoo Parlor',
    'tattoo-parlor': 'Tattoo Parlor',
    'local-massage-therapist': 'Local Massage Therapist',
    'massage-therapist': 'Massage Therapist',
    'local-physical-therapist': 'Local Physical Therapist',
    'physical-therapist': 'Physical Therapist',
    'local-chiropractor': 'Local Chiropractor',
    'chiropractor': 'Chiropractor',
    'local-dentist': 'Local Dentist',
    'dentist': 'Dentist',
    'local-optometrist': 'Local Optometrist',
    'optometrist': 'Optometrist',
    'local-orthodontist': 'Local Orthodontist',
    'orthodontist': 'Orthodontist',
    'local-pediatrician': 'Local Pediatrician',
    'pediatrician': 'Pediatrician',
    'local-physician': 'Local Physician',
    'physician': 'Physician',
    'local-psychiatrist': 'Local Psychiatrist',
    'psychiatrist': 'Psychiatrist',
    'local-therapist': 'Local Therapist',
    'therapist': 'Therapist',
    'local-urgent-care': 'Local Urgent Care',
    'urgent-care': 'Urgent Care',
    'local-veterinarian': 'Local Veterinarian',
    'veterinarian': 'Veterinarian',
    'local-eyecare': 'Local Eyecare',
    'eyecare': 'Eyecare',
    'local-eyewear': 'Local Eyewear',
    'eyewear': 'Eyewear',
    'local-eyewear-repair': 'Local Eyewear Repair',
    'eyewear-repair': 'Eyewear Repair',
    'local-eyewear-store': 'Local Eyewear Store',
    'eyewear-store': 'Eyewear Store',
}

// You can also export other city-related utility functions here if needed
export function getNicheDisplayName(nicheSlug) {
    return validNiches[nicheSlug] || null;
}