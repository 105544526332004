<template>
  <div>
    <section
      class="py-7 py-xl-9 bg-cover"
      style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/bg-5 copy.png');"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
              Client-Focused, Innovation-Driven
            </p>
            <h1
              class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Explore Our Projects
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white">
      <div class="row no-gutters overflow-hidden">
        <div
          class="col-10 col-lg-6 pe-none bg-gradient-primary skew-x-n45 ml-n7"
          style="height: 100px;"
        ></div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-between gy-7 mb-7">
          
          <div class="col-12 col-lg-6 order-2" data-aos="fade-right">
            <h2
              class="h1 fw-bold text-gradient-primary text-uppercase mt-3 mb-3"
            >
              Slo-Pitch Central
            </h2>
            <p class="text-secondary mb-3">
              The website serves as a comprehensive platform for slo-pitch leagues and tournaments, offering a user-friendly interface and essential functionalities for organizers and players. Its straightforward design ensures easy navigation, and the incorporation of tools for managing teams, schedules, and scores directly on the platform adds convenience.
            </p>
            <p class="text-secondary mb-3">
              While it's a valuable resource for the slo-pitch sports community, enhancing mobile responsiveness, loading speed, and integrating more interactive elements or social features could further improve the user experience.
            </p>
            <p class="text-secondary mb-4">
              <strong class="text-black">Explore more at <a href="https://slopitchcentral.com" class="text-black">slopitchcentral.com</a>.</strong>
            </p>

          </div>
          <div class="col-12 col-lg-6 order-1" data-aos="fade-left">
            <img src="https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/CleanShot 2024-04-11 at 20.12.20.png" class="img-fluid" />
          </div>
        </div>
        <div class="row align-items-center justify-content-between gy-7 mb-7">
          <div class="col-12 col-lg-6 order-2" data-aos="fade-left">
            <img src="/media/img-6.png" class="img-fluid" />
          </div>
          <div class="col-12 col-lg-6 order-1" data-aos="fade-right">
            <h2
              class="h1 fw-bold text-gradient-primary text-uppercase mt-3 mb-3"
            >
              Discord Bots
            </h2>
            <p class="text-secondary mb-3">
              Discord is a platform that at one time, was specifically for
              gamers to get together and create a community for their favourite
              video game. However, as their brand has grown, so has the variety
              of reasons to use their platform.
              <strong class="text-black"
                >We've built multiple bots for Discord, but the one we're most
                proud of is our MLB® The Show™ bot.</strong
              >
            </p>
            <p class="text-secondary mb-3">
              MLB® The Show™ is the most popular and famous baseball video game
              - and it's community is growing daily. We've built a bot that taps
              directly into their API and serves things like player cards,
              player stats, prices and more directly in Discord.
              <strong class="text-black"
                >Currently being used in over 20 MLB® The Show™ related Discord
                groups,</strong
              >
              these features allow players to access information that otherwise,
              is only accessible on the official website, quickly, without
              leaving their communities.
            </p>
            <p class="text-secondary mb-4">
              <strong class="text-black"
                >There is a big future for Discord, and we're excited to
                continue to find ways to tap into it.</strong
              >
            </p>
          </div>
        </div>
      </div>

      <div
        class="row no-gutters justify-content-end overflow-hidden mb-3 mb-lg-0"
      >
        <div
          class="col-10 col-lg-8 pe-none bg-gradient-primary skew-x-n45 mr-n7"
          style="height: 100px;"
        ></div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-between gy-7">
          <div class="col-12 col-lg-6 order-2 order-lg-1" data-aos="fade-right">
            <img src="/media/img-7.png" class="img-fluid" />
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2" data-aos="fade-left">
            <h2
              class="h1 fw-bold text-gradient-primary text-uppercase mt-3 mb-3"
            >
              Twitch Extensions
            </h2>
            <p class="text-secondary mb-3">
              Twitch is primarily a
              <strong class="text-black">video game streaming website</strong>
              but, similar to Discord, has continued to grow beyond its borders.
              Just like us. Comparable to the Discord Bot - we’ve created a
              Twitch Extension for MLB® The Show™ - which is currently installed
              and integrated on more than 100 unique Twitch streaming pages.
              Extensions like this enhance the viewers time when watching their
              favourite stream, which ultimately results in more revenue for the
              streamer.
            </p>
            <p class="text-secondary mb-4">
              The more ability a steamer has to keep their audience engaged, the
              more likely they are to receive subs, or have their subscribers
              gift subs to fellow streamers.
            </p>
          </div>
        </div>
      </div>
      <div class="row no-gutters overflow-hidden mt-7 mb-3 my-lg-0">
        <div
          class="col-10 col-lg-3 pe-none bg-gradient-primary skew-x-n45 ml-n7"
          style="height: 100px;"
        ></div>
      </div>

      <div class="row no-gutters justify-content-end overflow-hidden ">
        <div
          class="d-none d-lg-block col-10 col-lg-3 pe-none bg-gradient-primary skew-x-n45 mr-n7 mb-3 mb-lg-0"
          style="height: 100px;"
        ></div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-between gy-7">
          <div class="col-12 col-lg-6 order-2" data-aos="fade-left">
            <img src="/media/img-8.png" class="img-fluid" />
          </div>
          <div class="col-12 col-lg-6 order-1" data-aos="fade-right">
            <h2
              class="h1 fw-bold text-gradient-primary text-uppercase mt-3 mb-3"
            >
              Umpire Cheat Sheet
            </h2>
            <p class="text-secondary mb-3">
              <strong class="text-black"
                >This is just one example of our amazing capabilities</strong
              >
              - in this case, we saw a need and filled it. Our owner, Shane
              Heyworth, sometimes likes to umpire slo-pitch baseball on the
              weekends. Upon joining the organization in his local city, he
              noticed that many of the leagues and tournaments adopt different
              rulesets. His local Umpire-In-Chief would send out a spreadsheet
              that would be printed out and stored in the umpires pocket.
            </p>
            <p class="text-secondary mb-4">
              One day it rained unexpectedly, and Shane’s cheat sheet was
              ruined. He came home that weekend and was inspired to prepare an
              app that would store all of these cheat sheets easily, and in a
              way that would be accessible for all his fellow umpires - right on
              their mobile phone. Available both on iOS and Android, the Umpire
              Cheat Sheet is used by hundreds of umpires in Shane’s local
              organization, and has brought quite a bit of notoriety to his name
              when he plays locally.
            </p>
            <div class="form-row gy-4">
              <div class="col-auto">
                <a class="d-block text-decoration-none" href="#!">
                  <img
                    src="/media/appstore-badge.png"
                    class="w-auto"
                    height="50"
                  />
                </a>
              </div>
              <div class="col-auto">
                <a class="d-block text-decoration-none" href="#!">
                  <img
                    src="/media/google-play-badge.png"
                    class="w-auto"
                    height="50"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row no-gutters justify-content-end justify-content-lg-start overflow-hidden mt-7 mt-lg-3 mt-xxl-0 mb-3 mb-lg-7"
      >
        <div
          class="col-10 col-lg-8 pe-none bg-gradient-primary skew-x-n45 ml-n7 mr-n7"
          style="height: 100px;"
        ></div>
      </div>

      

      <div class="row no-gutters justify-content-end overflow-hidden">
        <div
          class="d-none d-lg-block col-10 col-lg-3 pe-none bg-gradient-primary skew-x-n45 mr-n7"
          style="height: 100px;"
        ></div>
      </div>
    </section>
    <ConnectWithUs></ConnectWithUs>
    <OurLocation></OurLocation>
  </div>
</template>

<script>
import ConnectWithUs from "../components/ConnectWithUs.vue";
import OurLocation from "../components/OurLocation.vue";
export default {
  name: "ProjectPage",
  components: { ConnectWithUs, OurLocation },
};
</script>
