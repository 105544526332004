export const validCities = {
    'toronto': 'Toronto',
    'hamilton': 'Hamilton',
    'burlington': 'Burlington',
    'brantford': 'Brantford',
    'smithville': 'Smithville',
    'dunnville': 'Dunnville',
    'ancaster': 'Ancaster',
    'niagara': 'Niagara',
    'oakville': 'Oakville',
    'guelph': 'Guelph',
    'cambridge': 'Cambridge',
    'kitchener': 'Kitchener',
    'milton': 'Milton',
    'puslinch': 'Puslinch',
    'hagersville': 'Hagersville',
    'caledonia': 'Caledonia',
    'haldimand county': 'Haldimand County',
    'cayuga': 'Cayuga',
    'port-dover': 'Port Dover',
    'woodstock': 'Woodstock',
    'jarvis': 'Jarvis',
    'st-catherines': 'St. Catharines', // Note the correction here
    'lincoln': 'Lincoln',
    'niagara-falls': 'Niagara Falls',
    'welland': 'Welland',
    // 'dunnville' is duplicated in your list, so ensure to include it only once or handle it appropriately
    'orillia': 'Orillia',
    'barrie': 'Barrie',
    'newmarket': 'Newmarket',
    'simcoe': 'Simcoe',
    // 'woodstock' is duplicated in your list
    'tilsonburg': 'Tilsonburg',
    // 'cambridge' is duplicated in your list
    'georgina': 'Georgina',
    'warminster': 'Warminster',
    'brechin': 'Brechin',
    'washago': 'Washago',
    'oro-station': 'Oro Station',
    'uxbridge': 'Uxbridge',
    'elmvale': 'Elmvale',
    'new-tecumseth': 'New Tecumseth',
    'mansfield': 'Mansfield',
    'mono': 'Mono',
    'dundas': 'Dundas',
    'flamborough': 'Flamborough',
    // Continue with the rest of the cities as needed
}

// You can also export other city-related utility functions here if needed
export function getCityDisplayName(citySlug) {
    return validCities[citySlug] || null;
}