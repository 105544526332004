<template>
    <section class="py-7 py-xl-9 bg-light">
      <div class="container">
        <h2 class="display-4 fw-bold text-center text-gradient-primary text-uppercase mb-7">
          Our Services for {{ niche }} Web Development
        </h2>
        <div class="row g-4">
          <div class="col-6 col-md-4 px-2 py-2" v-for="service in servicesWithNiche" :key="service.id">
            <a :href="service.url" class="text-decoration-none">
              <div class="service-box bg-black shadow-sm hover-lift d-flex align-items-center justify-content-center p-4 border border-2 border-warning" style="aspect-ratio: 1.6;">
                <h3 class="h1 mb-0 text-center text-gradient-primary text-uppercase fw-bold">{{ service.name }}</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </template>


<script>
export default {
  props: {
    niche: {
      type: String,
      default: 'sports'
    }
  },
  data() {
    return {
      services: [
        { id: 1, name: "Web Design", url: "web-design" },
        { id: 2, name: "Web Development", url: "web-development" },
        { id: 3, name: "App Development", url: "app-development" },
        { id: 4, name: "SEO Optimization", url: "seo-optimization" },
        { id: 5, name: "Growth Strategizing", url: "growth-strategizing" },
        { id: 6, name: "Discord Bot Development", url: "discord-bot-development" },
        { id: 7, name: "Twitch Extension Development", url: "twitch-extension-development" },
        { id: 8, name: "Chrome Extension Development", url: "chrome-extension-development" },
        { id: 9, name: "Socket Development", url: "socket-development" },
      ],
    }
  },
  computed: {
    servicesWithNiche() {
        console.log('wtf')
      return this.services.map(service => ({
        ...service,
        url: `/${this.niche}/${service.url}`
      }))
    }
  }
}
</script>

<style scoped>
.border-warning {
  border-color: gold !important;
}

.service-box {
  transition: background-color 0.3s ease;
}

.service-box:hover {
  background-color: #333 !important;
}

.text-gradient-primary {
  background-image: linear-gradient(45deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}
</style>