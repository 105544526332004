<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/appdev.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            Apps that thrill & empower your customers
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Why Choose BlacktieCollab?
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where we bring your mobile visions to life with our expert app development services. Specializing in creating high-performance, feature-rich mobile applications, we ensure your app not only looks great but operates seamlessly across all devices and platforms.</p>

                        <p>Rejecting the one-size-fits-all approach, Blacktie Collaborative delves into the specific needs of your business. We align our app development strategy with your company’s goals, brand identity, and target audience, crafting customized applications that offer unique user experiences and drive engagement.</p>

                        <p>Unlike DIY app makers that often lead to generic results, our professional team handles complex app development challenges. We focus on robust backend integrations, user-friendly interfaces, and cutting-edge functionalities that elevate your app’s performance and user satisfaction.</p>

                        <p>Our services extend beyond <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span>, catering to clients wherever they are located. The dedicated, in-house team at Blacktie Collaborative ensures that your application adheres to the highest standards, reflecting the core values of your brand and industry—without outsourcing any work, ensuring consistency and quality in every line of code we write.</p>

                        <p>Recognizing the critical importance of responsive and adaptive design in today’s mobile-centric world, we guarantee that your app provides optimal user experience, whether accessed on smartphones, tablets, or other devices. This responsiveness enhances user engagement and contributes positively to your app’s success.</p>

                        <p>Blacktie Collaborative also specializes in developing e-commerce apps that not only attract visitors but convert them into loyal customers. Our tailored e-commerce solutions are designed to facilitate seamless shopping experiences, integrating easy navigation, secure payment gateways, and intuitive design to maximize user interaction and sales conversions.</p>

                        <p>If you are looking to develop a mobile app that stands out in the competitive digital marketplace, contact Blacktie Collaborative today. No matter your location, from <span v-if="city">{{ cityDisplayName }}</span> to beyond, our app development experts are ready to assist you. Click the <strong>Contact Us</strong> button below to discuss how we can bring your app idea to life.</p>

                    </div>
                </div>
            </div>
        </section>
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>

        
        <ConnectWithUs></ConnectWithUs>
        <Niches></Niches>
        <OurLocation></OurLocation>
        
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import Niches from '../components/Niches.vue'
import OurLocation from '../components/OurLocation.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'

export default {
  name: 'AppDevelopmentPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, Niches, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'App Development';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/app-development';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative delivers custom, high-performance ${this.pageTitle.toLowerCase()} tailored to your business needs. Our expert team creates feature-rich applications with seamless user experiences across all devices and platforms.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative delivers custom, high-performance ${this.pageTitle.toLowerCase()} tailored to your business needs. Our expert team creates feature-rich applications with seamless user experiences across all devices and platforms.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>