import { render, staticRenderFns } from "./WebDevelopmentPage.vue?vue&type=template&id=7bee2e60&"
import script from "./WebDevelopmentPage.vue?vue&type=script&lang=js&"
export * from "./WebDevelopmentPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports