import Vue from "vue";
import VueRouter from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faFutbol,
  faHeart,
  faLightbulb,
  faRecycle,
  faBars,
  faChevronRight,
  faLongArrowAltRight,
  faStar,
  faServer,
  faPlus,
  faMinus,
  faPhoneAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Flickity from "vue-flickity";
import App from "./App.vue";
import Routes from "./routes";
import VueCompositionAPI from "@vue/composition-api";
import VueMeta from "vue-meta";

import AOS from "aos";
import "aos/dist/aos.css";

// import "./assets/css/bootstrap.min.css";
// import "./assets/css/main.css";
import "./assets/scss/styles.scss";

Vue.use(VueRouter);

Vue.use(BootstrapVue);

Vue.use(VueCompositionAPI);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

library.add(
  faHome,
  faFutbol,
  faHeart,
  faLightbulb,
  faRecycle,
  faBars,
  faChevronRight,
  faLongArrowAltRight,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faStar,
  faServer,
  faPlus,
  faMinus,
  faYoutube,
  faPhoneAlt,
  faPhone,
  faEnvelope
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("flickity", Flickity);

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: Routes,
});

new Vue({
  render: (h) => h(App),
  router,
  mounted() {
    AOS.init({
      once: true,
      duration: 600,
    });
  },
}).$mount("#app");
