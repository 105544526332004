<template>
    <div>
      <section
        class="py-7 py-xl-9 bg-cover"
        style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/webdesign-1 copy.png');"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                Engage your audience with interactive experiences
              </p>
              <h1
                class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                {{ pageTitle }}
              </h1>
            </div>
          </div>
        </div>
      </section>
  
      <section class="py-7 py-xl-9 position-relative">
        <div
          class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
          style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
        ></div>
        <div class="container">
          <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
            {{ pageTitle }}
          </h2>
          <div class="row justify-content-center gy-7">
            <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
              <p>Welcome to Blacktie Collaborative, where our Twitch Extension Development services are designed to revolutionize the way broadcasters interact with their audiences on Twitch. We create custom extensions that enhance viewer engagement and provide innovative solutions to improve the streaming experience.</p>
  
              <p>At Blacktie Collaborative, we understand the unique dynamics of the Twitch platform and the need for personalized interactions between broadcasters and their viewers. Our team specializes in developing extensions that are not only visually appealing but also highly functional, offering features like live polling, real-time game data integration, and more.</p>
  
              <p>Our development process begins with a deep dive into your specific needs and goals as a broadcaster. We consider your brand, your audience, and the type of content you produce to ensure that the extensions we develop are perfectly aligned with your channel's ethos and enhance your broadcast strategy.</p>
  
              <p>Serving clients globally, including <span v-if="cityDisplayName">{{ cityDisplayName }}</span><span v-else>Hamilton</span> and beyond, Blacktie Collaborative is committed to delivering cutting-edge Twitch Extension Development services. Our team employs modern web technologies and follows best practices to ensure that your extensions are reliable, user-friendly, and scalable.</p>
  
              <p>Recognizing the importance of engagement in building and maintaining a loyal viewer base, our extensions are designed to keep your audience entertained, involved, and coming back for more. From interactive games to viewer loyalty programs, we equip broadcasters with the tools they need to stand out in the crowded Twitch landscape.</p>
  
              <p>If you're ready to take your Twitch channel to the next level with custom extensions that captivate and engage your audience, Blacktie Collaborative is here to assist. No matter where you are, from <span v-if="cityDisplayName">{{ cityDisplayName }}</span> to any corner of the globe, our experts are ready to help you enhance your digital presence on Twitch.</p>
  
              <p>To find out more about our Twitch Extension Development services and how we can help you transform your Twitch channel, click the <strong>Contact Us</strong> button below and start a conversation with our development team today.</p>
            </div>
          </div>
        </div>
      </section>
  
      <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
      <LoveFromOurClients></LoveFromOurClients>
      <ConnectWithUs></ConnectWithUs>
      <Niches></Niches>
      <OurLocation></OurLocation>
    </div>
  </template>
  
  <script>
  import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
  import ConnectWithUs from '@/components/ConnectWithUs.vue'
  import LoveFromOurClients from '../components/LoveFromOurClients.vue'
  import Niches from '../components/Niches.vue'
  import OurLocation from '../components/OurLocation.vue'
  import { validCities, getCityDisplayName } from '@/utils/cityData.js'
  import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'
  
  export default {
    name: 'TwitchExtensionDevelopmentPage',
    components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, Niches, OurLocation },
    data() {
      return {
        city: null,
        cityDisplayName: '',
        niche: null,
        nicheDisplayName: '',
        workWithUsBoxes: [
          // Add your workWithUsBoxes data here
        ]
      }
    },
    methods: {
      updatePageData(to) {
        const { niche, slug } = to.params;
        
        if (niche && Object.keys(validNiches).includes(niche)) {
          this.niche = niche;
          this.nicheDisplayName = getNicheDisplayName(niche);
        } else {
          this.niche = null;
          this.nicheDisplayName = '';
        }
  
        if (slug && Object.keys(validCities).includes(slug)) {
          this.city = slug;
          this.cityDisplayName = getCityDisplayName(slug);
        } else {
          this.city = null;
          this.cityDisplayName = '';
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => vm.updatePageData(to));
    },
    async beforeRouteUpdate(to, from, next) {
      this.updatePageData(to);
      await this.$nextTick();
      next();
    },
    mounted() {
      this.updatePageData(this.$route);
    },
    computed: {
      pageTitle() {
        let title = 'Twitch Extension Development';
        if (this.nicheDisplayName) {
          title = `${title} for ${this.nicheDisplayName}`;
        }
        if (this.cityDisplayName) {
          title = `${title} in ${this.cityDisplayName}`;
        }
        return title;
      },
      canonicalLink() {
        let link = 'https://blacktiecollab.com/twitch-extension-development';
        if (this.niche) {
          link = `${link}/${this.niche}`;
        }
        if (this.city) {
          link = `${link}/${this.city}`;
        }
        return link;
      }
    },
    metaInfo() {
      return {
        title: `${this.pageTitle} - Blacktie Collaborative`,
        meta: [
          {
            name: 'description',
            content: `Blacktie Collaborative offers custom ${this.pageTitle.toLowerCase()} services, creating engaging and interactive Twitch extensions. Our expert team designs and develops extensions that enhance viewer engagement and provide innovative solutions to improve the streaming experience.`
          },
          {
            property: 'og:title',
            content: `${this.pageTitle} - Blacktie Collaborative`
          },
          {
            property: 'og:description',
            content: `Blacktie Collaborative offers custom ${this.pageTitle.toLowerCase()} services, creating engaging and interactive Twitch extensions. Our expert team designs and develops extensions that enhance viewer engagement and provide innovative solutions to improve the streaming experience.`
          },
          {
            rel: 'canonical',
            href: this.canonicalLink
          }
        ]
      };
    },
  }
  </script>
  
  <style scoped>
  /* Add any scoped styles here if needed */
  </style>