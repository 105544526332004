import HomePage from './pages/HomePage.vue'
import SportPage from './pages/SportPage.vue'
import LifestylePage from './pages/LifestylePage.vue'
import ProjectPage from './pages/ProjectPage.vue'
import ContactPage from './pages/ContactPage.vue'
import BrandPage from './pages/BrandPage.vue'
import BlogPage from './pages/BlogPage.vue'
import SingleBlogPost from './pages/SingleBlogPost.vue'
// import DynamicCitiesPage from './pages/DynamicCitiesPage.vue'
import WebDesignPage from './pages/WebDesignPage.vue'
import WebDevelopmentPage from './pages/WebDevelopmentPage.vue'
import AppDevelopmentPage from './pages/AppDevelopmentPage.vue'
import SeoGrowthPage from './pages/SeoGrowthPage.vue'
import GrowthStrategizingPage from './pages/GrowthStrategizingPage.vue'
import DiscordBotDevelopmentPage from './pages/DiscordBotDevelopmentPage.vue'
import TwitchExtensionDevelopmentPage from './pages/TwitchExtensionDevelopmentPage.vue'
import ChromeExtensionDevelopmentPage from './pages/ChromeExtensionDevelopmentPage.vue'
import SocketDevelopmentPage from './pages/SocketDevelopmentPage.vue'
import ProcessPage from './pages/ProcessPage.vue'
import SiteAssessmentResult from './pages/SiteAssessmentResult.vue'
import FreeSiteAssessmentPage from './pages/FreeSiteAssessmentPage.vue'
import NichePage from './pages/NichePage.vue'

export default [
  { path: '/', component: HomePage, name: 'home'},
  { path: '/site-assessment/:url', component: SiteAssessmentResult, name: 'site-assessment-result' },
  { path: '/our-process', component: ProcessPage, name: 'process' },
  { path: '/sports', component: SportPage, name: 'sports'},
  { path: '/lifestyle', component: LifestylePage, name: 'lifestyle'},
  { path: '/projects', component: ProjectPage, name: 'project'},
  { path: '/contact-us', component: ContactPage, name: 'contact-us'},
  { path: '/free-site-assessment', component: FreeSiteAssessmentPage, name: 'free-site-assessment' },
  { path: '/brand/:slug', component: BrandPage, name: 'brand'},
  { path: '/blog', component: BlogPage, name: 'blog'},
  { path: '/blog/:slug', component: SingleBlogPost, name: 'single-blog-post'},
  { path: '/web-design', component: WebDesignPage, name: 'web-design' },
  { path: '/web-development', component: WebDevelopmentPage, name: 'web-development' },
  { path: '/app-development', component: AppDevelopmentPage, name: 'app-development' },
  { path: '/seo-optimization', component: SeoGrowthPage, name: 'seo-growth' },
  { path: '/growth-strategizing', component: GrowthStrategizingPage, name: 'growth-strategizing' },
  { path: '/discord-bot-development', component: DiscordBotDevelopmentPage, name: 'discord-bot-development' },
  { path: '/twitch-extension-development', component: TwitchExtensionDevelopmentPage, name: 'twitch-extension-development' },
  { path: '/chrome-extension-development', component: ChromeExtensionDevelopmentPage, name: 'chrome-extension-development' },
  { path: '/socket-development', component: SocketDevelopmentPage, name: 'socket-development' },
  
  { path: '/:slug-web-design', component: WebDesignPage, name: 'dynamic-web-design'},
  { path: '/:slug-web-development', component: WebDevelopmentPage, name: 'dynamic-web-development'},
  { path: '/:slug-app-development', component: AppDevelopmentPage, name: 'dynamic-app-development'},
  { path: '/:slug-seo', component: SeoGrowthPage, name: 'dynamic-seo'},
  { path: '/:slug-seo-optimization', component: SeoGrowthPage, name: 'dynamic-seo'},
  { path: '/:slug-growth-strategizing', component: GrowthStrategizingPage, name: 'dynamic-seo'},
  { path: '/:slug-discord-bot-development', component: DiscordBotDevelopmentPage, name: 'dynamic-seo'},
  { path: '/:slug-twitch-extension-development', component: TwitchExtensionDevelopmentPage, name: 'dynamic-seo'},
  { path: '/:slug-chrome-extension-development', component: ChromeExtensionDevelopmentPage, name: 'dynamic-seo'},
  { path: '/:slug-socket-development', component: SocketDevelopmentPage, name: 'dynamic-seo'},

  { path: '/:niche/web-design', component: WebDesignPage, name: 'web-design' },
  { path: '/:niche/web-development', component: WebDevelopmentPage, name: 'web-development' },
  { path: '/:niche/app-development', component: AppDevelopmentPage, name: 'app-development' },
  { path: '/:niche/seo-optimization', component: SeoGrowthPage, name: 'seo-growth' },
  { path: '/:niche/growth-strategizing', component: GrowthStrategizingPage, name: 'growth-strategizing' },
  { path: '/:niche/discord-bot-development', component: DiscordBotDevelopmentPage, name: 'discord-bot-development' },
  { path: '/:niche/twitch-extension-development', component: TwitchExtensionDevelopmentPage, name: 'twitch-extension-development' },
  { path: '/:niche/chrome-extension-development', component: ChromeExtensionDevelopmentPage, name: 'chrome-extension-development' },
  { path: '/:niche/socket-development', component: SocketDevelopmentPage, name: 'socket-development' },

  { path: '/:niche/:slug-web-design', component: WebDesignPage, name: 'dynamic-web-design'},
  { path: '/:niche/:slug-web-development', component: WebDevelopmentPage, name: 'dynamic-web-development'},
  { path: '/:niche/:slug-app-development', component: AppDevelopmentPage, name: 'dynamic-app-development'},
  { path: '/:niche/:slug-seo', component: SeoGrowthPage, name: 'dynamic-seo'},
  { path: '/:niche/:slug-seo-optimization', component: SeoGrowthPage, name: 'dynamic-seo'},
  { path: '/:niche/:slug-growth-strategizing', component: GrowthStrategizingPage, name: 'dynamic-seo'},
  { path: '/:niche/:slug-discord-bot-development', component: DiscordBotDevelopmentPage, name: 'dynamic-seo'},
  { path: '/:niche/:slug-twitch-extension-development', component: TwitchExtensionDevelopmentPage, name: 'dynamic-seo'},
  { path: '/:niche/:slug-chrome-extension-development', component: ChromeExtensionDevelopmentPage, name: 'dynamic-seo'},
  { path: '/:niche/:slug-socket-development', component: SocketDevelopmentPage, name: 'dynamic-seo'},

  { path: '/:niche', component: NichePage, name: 'niche'}, // catch all for niches that are not ready yet
]