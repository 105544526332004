<template>
  <div>
    <section
      class="py-6 py-xl-9 bg-cover"
      style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/CleanShot 2024-06-17 at 16.54.56.png');"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 data-aos="fade-up" data-aos-delay="100">
              <span class="text-white text-uppercase fw-bold">
                We take your inefficiencies
              </span>
              <br>
              <span class="display-4 text-gradient-primary text-uppercase fw-bold">
                and turn them into <br>dollar signs for you
              </span>
              <!-- <br>
              <span class="text-white text-uppercase fw-bold">
                Into Simple,
              </span>
              <br>
              <span class="display-3 text-gradient-primary text-uppercase fw-bold">
                Powerful Solutions
              </span> -->
            </h1>
            <p style="color: #fff; font-size: 18px;">Running a profitable, sustainable business in 2024 has never been tougher. Let us help you find a path to sustainability.</p>
            <br>
            <router-link
              class="btn btn-outline-gradient-primary btn-append"
              to="/contact-us"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              Find out how
              <span class="btn-append-icon">
                <font-awesome-icon icon="chevron-right" />
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <WhyWorkWithUs></WhyWorkWithUs>
    <LoveFromOurClients></LoveFromOurClients>
    <FeaturedWork></FeaturedWork>
    

    <WhatSetsUsApart></WhatSetsUsApart>

    <!-- <section>
      <div class="row no-gutters">
        <div class="col-12 col-lg-6">
          <div class="card h-100">
            <img src="/media/sport.jpg" class="card-img" alt="We create web platforms for the sports niche" />
            <div class="card-img-overlay">
              <div class="d-flex align-items-center flex-column h-100">
                <router-link
                  class="mt-auto h1 fw-bold btn-outline-gradient-primary border-2 border-solid text-center text-uppercase text-decoration-none mb-0 py-4 px-7"
                  to="/sports"
                  >Sports</router-link
                >
                <div class="mt-auto align-self-end ">
                  <router-link class="btn btn-dark btn-ico" to="/sports">
                    <font-awesome-icon icon="long-arrow-alt-right" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card h-100">
            <img src="/media/lifestyle.jpg" class="card-img" alt="We create web platforms for lifestyle businesses" />
            <div class="card-img-overlay">
              <div class="d-flex align-items-center flex-column h-100">
                <router-link
                  class="mt-auto h1 fw-bold btn-outline-gradient-primary border-2 border-solid text-center text-uppercase mb-0 py-4 px-7 text-decoration-none"
                  to="/lifestyle"
                  >Lifestyle</router-link
                >
                <div class="mt-auto align-self-end ">
                  <router-link class="btn btn-dark btn-ico" to="/lifestyle">
                    <font-awesome-icon icon="long-arrow-alt-right" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <PricingComponent></PricingComponent>
    <ConnectWithUs></ConnectWithUs>
    <OurLocation></OurLocation>
  </div>
</template>

<script>
import ConnectWithUs from "../components/ConnectWithUs.vue";
import LoveFromOurClients from '../components/LoveFromOurClients.vue';
import WhyWorkWithUs from '../components/WhyWorkWithUs.vue';
import WhatSetsUsApart from '../components/WhatSetsUsApart.vue';
import FeaturedWork from '../components/FeaturedWork.vue';
import PricingComponent from '../components/PricingComponent.vue';
import OurLocation from '../components/OurLocation.vue';
export default {
  name: "HomePage",
  metaInfo: {
    title: 'Websites Without Limitation - Blacktie Collaborative',
    meta: [
      {
        name: 'description',
        content: 'Blacktie Collaborative offers enterprise-quality solutions for small businesses, lifestyle coaches, and sports technology.'
      },
      {
        property: 'og:title',
        content: 'Home - Blacktie Collaborative'
      },
      {
        property: 'og:description',
        content: 'Blacktie Collaborative offers enterprise-quality solutions for small businesses, lifestyle coaches, and sports technology.'
      },
      {
        rel: 'canonical',
        href: 'https://blacktiecollab.com/'
      }
      // Add more meta tags as needed
    ]
  },
  components: { ConnectWithUs, LoveFromOurClients, WhyWorkWithUs, WhatSetsUsApart, FeaturedWork,PricingComponent, OurLocation },
};
</script>
