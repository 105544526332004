<template>
    <span>
        <div class="container">
            <h2 class="display-4 fw-bold text-center text-uppercase mt-4">
                {{ siteUrl }}
            </h2>
            <h2 class="display-5 fw-bold text-center text-uppercase" style="color: #dab665;">
                Project Overview
            </h2>
            <div class="current-title">
                Current Website Analysis
            </div>
            <div class="row" v-if="currentWebsiteScreenshot">
                <div class="col-md-8">
                    <img :src="'https://assessment-cms.onrender.com' + currentWebsiteScreenshot.sizes.large.url" width="100%" alt="">
                </div>
            
                <div class="col-md-4">
                    <ul class="checklist">
                        
                        <li>
                            <label class="custom-checkbox" v-if="assessmentItems">
                                <input type="checkbox" v-model="assessmentItems.responsive_design" disabled>
                                <span class="checkmark"></span>
                                <div class="text-content">
                                    <span class="checkbox-title">Responsive Design</span>
                                    <p class="checkbox-description"></p>
                                </div>
                            </label>
                        </li>
                        <li>
                            <label class="custom-checkbox" v-if="assessmentItems">
                                <input type="checkbox" v-model="assessmentItems.seo_optimization" disabled>
                                <span class="checkmark"></span>
                                <div class="text-content">
                                    <span class="checkbox-title">SEO Optimization</span>
                                    <p class="checkbox-description"></p>
                                </div>
                            </label>
                        </li>
                        <li>
                            <label class="custom-checkbox" v-if="assessmentItems">
                                <input type="checkbox" v-model="assessmentItems.speed_optimizations" disabled>
                                <span class="checkmark"></span>
                                <div class="text-content">
                                    <span class="checkbox-title">Speed Optimization</span>
                                    <p class="checkbox-description"></p>
                                </div>
                            </label>
                        </li>
                        <li>
                            <label class="custom-checkbox" v-if="assessmentItems">
                                <input type="checkbox" v-model="assessmentItems.update_architectures" disabled>
                                <span class="checkmark"></span>
                                <div class="text-content">
                                    <span class="checkbox-title">New Architectures</span>
                                    <p class="checkbox-description"></p>
                                </div>
                            </label>
                        </li>
                        <li>
                            <label class="custom-checkbox" v-if="assessmentItems">
                                <input type="checkbox" v-model="assessmentItems.high_quality_images" disabled>
                                <span class="checkmark"></span>
                                <div class="text-content">
                                    <span class="checkbox-title">High Quality Images</span>
                                    <p class="checkbox-description"></p>
                                </div>
                            </label>
                        </li>
                        <li>
                            <label class="custom-checkbox" v-if="assessmentItems">
                                <input type="checkbox" v-model="assessmentItems.uses_video_effectively" disabled>
                                <span class="checkmark"></span>
                                <div class="text-content">
                                    <span class="checkbox-title">Uses Video Effectively</span>
                                    <p class="checkbox-description"></p>
                                </div>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="current-title">
                Current Content Analysis
            </div>
            <div class="row">
                <div class="col-md-4" v-for="page in contentAnalysis" :key="page.id">
                    <div class="card" style="padding: 20px;">
                        <h4>{{ page.page_title }}</h4>
                        <p>Url: {{ siteUrl }}{{ page.page_url }}</p>
                        <ul class="checklist" v-if="page.content_checklist">
                            <li>
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="page.content_checklist.captures_keyword_opportunities" disabled>
                                    <span class="checkmark"></span>
                                    <div class="text-content">
                                        <span class="checkbox-title">Captures Keyword Opportunties</span>
                                        <!-- <p class="checkbox-description">{{ item.description }}</p> -->
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="page.content_checklist.includes_call_to_action" disabled>
                                    <span class="checkmark"></span>
                                    <div class="text-content">
                                        <span class="checkbox-title">Includes Call to Action</span>
                                        <!-- <p class="checkbox-description">{{ item.description }}</p> -->
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="page.content_checklist.optimized_for_readability" disabled>
                                    <span class="checkmark"></span>
                                    <div class="text-content">
                                        <span class="checkbox-title">Optimized for Readability</span>
                                        <!-- <p class="checkbox-description">{{ item.description }}</p> -->
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="page.content_checklist.positive_effective_engaging_content" disabled>
                                    <span class="checkmark"></span>
                                    <div class="text-content">
                                        <span class="checkbox-title">Positive, Effective, Engaging Content</span>
                                        <!-- <p class="checkbox-description">{{ item.description }}</p> -->
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="page.content_checklist.takes_advantage_of_branding_opportunities" disabled>
                                    <span class="checkmark"></span>
                                    <div class="text-content">
                                        <span class="checkbox-title">Takes Advantage of Branding Opportunities</span>
                                        <!-- <p class="checkbox-description">{{ item.description }}</p> -->
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="website-grade">
                <h3 class="grade-title">Your Grade:</h3>
                <p class="grade-value">{{ currentWebsiteGrade }}</p>
            </div>
            <div class="text-center" style="margin-top: 40px;">
                <router-link class="btn btn-primary" to="/sports">
                    Let's get started
                </router-link>
            </div>
            
            <h2 class="display-5 fw-bold text-center text-uppercase mt-10" style="color: #dab665;">
                Our Recommendations
            </h2>
            <div class="fancy-title">
                Our Vision for {{  siteUrl }}
            </div>
            <div class="row" v-if="newImages">
                <div class="col-md-8">
                    <span v-if="newImages[0]">
                        <img :src="'https://assessment-cms.onrender.com' + newImages[0].image.sizes.large.url" width="100%" alt="">
                    </span>
                </div>
                <div class="col-md-4">
                    <span v-if="newImages[1]">
                        <img :src="'https://assessment-cms.onrender.com' + newImages[1].image.sizes.large.url" width="100%" alt="">
                    </span>
                    <span v-if="newImages[2]">
                        <img :src="'https://assessment-cms.onrender.com' + newImages[2].image.sizes.large.url" width="100%" alt="">
                    </span>
                </div>
            </div>
            <div class="fancy-title">
                Blog Topic Idea for {{ siteUrl }}
            </div>
            <div class="blog-area">
                <h4>{{ blogTopicIdea.topic }}</h4>
                <p>{{ blogTopicIdea.content_sample }}</p>
                <p style="color: #333;"><b>Keywords used:</b> {{  blogTopicIdea.keywords }}</p>
            </div>
            <div class="fancy-title">
                Recommended Package for {{ siteUrl }}
            </div>
            <ul class="checklist">
                <li v-for="item in recommendedPackage" :key="item.id">
                    <label class="custom-checkbox">
                        <input type="checkbox" v-model="item.id" disabled>
                        <span class="checkmark"></span>
                        <div class="text-content">
                            <span class="checkbox-title">{{ item.package_name }}</span>
                            <p class="checkbox-description">{{ item.package_description }}</p>
                            <p class="checkbox-price">{{ item.package_price }}</p>
                        </div>
                    </label>
                </li>
            </ul>
            <div class="fancy-title">
                Additional Notes & Next Steps
            </div>
            <div style="padding: 20px 0;">
                <p>{{ additionalNotes }}</p>
            </div>
            <div class="text-center" style="margin-top: 40px; padding-bottom: 100px;">
                <router-link class="btn btn-primary" to="/sports">
                    Let's get started
                </router-link>
                <br><br>
                <router-link class="btn btn-dark btn-sm" to="/sports">
                    No thank you
                </router-link>
            </div>

        </div>
    </span>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
export default {
    data() {
        return {
            siteUrl: this.$route.params.url,
            checklistItems: [],
            // checklistItems: [
            //     { id: 1, label: 'Responsive Design', description: 'Adapts to frequently used screen sizes with a singular design.', checked: true },
            //     { id: 2, label: 'SEO Optimization', description: 'Taking advantage of all SEO opportunities available, like pages dedicated to targeted keywords, alt text, etc.', checked: false },
            //     { id: 3, label: 'Speed Optimization', description: 'Site loads fast.', checked: true },
            //     { id: 4, label: 'Quality Images', description: 'High quality images increase the likelihood of customers trusting your brand.', checked: true },
            //     { id: 5, label: 'Use of Video', description: 'Videos are important for customer trust and attention to detail, giving a more complete look to your website.', checked: true },
            //     { id: 6, label: 'Using Up to Date Architectures', description: 'The site is using languages like CSS3, HTML5 and more.', checked: true },
            // ],
            contentAnalysis: [
                { id: 1, label: 'Captures keyword opportunities', description: '', checked: true },
                { id: 2, label: 'Optimized for readability', description: '', checked: true },
                { id: 3, label: 'Positive, effective voice', description: '', checked: true },
                { id: 4, label: 'Captures branding opportunities', description: '', checked: true },
                { id: 5, label: 'Compels purchases', description: '', checked: true },
            ],
            recommendedPackage: [],
            currentWebsiteAnalysis: null,
            currentWebsiteGrade: 'C+',
            whatCanBeImproved: 'SEO Optimization, Speed Optimization',
            additionalNotes: 'The site is in good shape, but could use some improvements to increase its visibility and speed.',
            blogTopicIdea: {
                topic: null,
                keywords: null,
                content_sample: null
            },
            adequateItems: [],
            inadequateItems: [],
            completelyMissingItems: [],
            assessmentItems: null,
            currentWebsiteScreenshot: null,
            newImages: []
        }
    },
    mounted: function() {
        const query = {
            website_url: {
                equals: this.siteUrl
            },
            depth: 3
        }
        const stringifiedQuery = qs.stringify(query)
        axios.get(`https://cms.blacktiecollab.com/all/api/assessments?${stringifiedQuery}`).then(results => {
            
            const doc = results.data.docs[0]
            console.log('doc: ', doc)
            this.blogTopicIdea = doc.blog_topic_idea
            this.recommendedPackage = doc.recommended_package
            this.contentAnalysis = doc.page_analysis
            this.adequateItems = doc.adequate_items
            this.inadequateItems = doc.inadequate_items
            this.completelyMissingItems = doc.completely_missing_items
            this.checklistItems = doc.current_assessment
            this.assessmentItems = doc.current_assessment.assessment_items
            this.additionalNotes = doc.additional_notes
            this.currentWebsiteGrade = doc.current_assessment.your_grade
            this.currentWebsiteScreenshot = doc.current_assessment.current_website_screenshot
            this.newImages = doc.new_images
        })
    }
}
</script>

<style scoped>
.fancy-title {
    color: #dab665;
    background: #000;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin: 0 auto;
    margin-top: 30px;
}

.current-title {
    color: #dab665;
    background: #333;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin: 0 auto;
    margin-top: 30px;
}

.checklist {
    list-style: none;
    padding: 0;
    margin-top: 30px;
}

.custom-checkbox {
    display: flex;
    align-items: center; /* Align checkbox vertically with the text content */
    font-size: 18px;
    user-select: none;
    margin-bottom: 20px; /* Space between items */
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: default;
    height: 0;
    width: 0;
}

.checkmark {
    position: relative;
    height: 35px;
    width: 35px;
    background-color: #eee;
    border-radius: 5px;
    margin-right: 20px; /* Space between checkbox and text */
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #dab665;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 11px;
    top: 3px;
    width: 12px;
    height: 24px;
    border: solid white;
    border-width: 0 5px 5px 0;
    transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.text-content {
    display: flex;
    flex-direction: column;
}

.checkbox-title {
    font-size: 18px;
    color: #333;
}

.checkbox-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
}

.website-grade {
    margin-top: 30px;
    text-align: center;
}

.grade-title, .recommendations-title {
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #333;
}

.grade-value {
    font-size: 50px;
    color: #dab665;
    font-weight: 700;
}

.blog-area {
    padding: 50px;
}
</style>