<template>
    <div>
      <div class="header-container">
        LAUNCH FAST | CANADIAN MADE | ALL INCLUSIVE PRICING
      </div>
        <b-navbar toggleable="xl" type="custom">
        <div class="container d-flex justify-content-center">
          <b-navbar-brand href="#">
            <!-- <img src="/media/logo.png" class="logo" /> -->
            <div class="logo">
              <div class="logo-text">Blacktie</div>
              <div class="sub-logo-text">Collaborative</div>
            </div>
          </b-navbar-brand>
        </div>
      </b-navbar>
      <Navigation></Navigation>
    </div>
</template>

<script>
import Navigation from "./Navigation.vue";
  export default {
    name: "Header",
    components: { Navigation },
    data() {
      return {
        // Add a data property for the current year
      };
    },
  };
</script>

<style scoped>
.header-container {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
}
</style>