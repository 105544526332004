<template>
  <footer class="py-6 py-xl-7 bg-black text-gray-300">
    <div class="container">
      <div class="row">
        <!-- Quick Links -->
        <div class="col-12 col-md">
          <h5 class="text-white">QUICK LINKS</h5>
          <ul class="list-unstyled">
            <li><a href="/about" class="text-decoration-none text-gray-300">About Us</a></li>
            <li><a href="/services" class="text-decoration-none text-gray-300">Services</a></li>
            <li><a href="/contact-us" class="text-decoration-none text-gray-300">Contact Us</a></li>
            <li><a href="/projects" class="text-decoration-none text-gray-300">Projects</a></li>
          </ul>
        </div>
        
        <!-- Proudly Serving -->
        <div class="col-12 col-md">
          <h5 class="text-white">PROUDLY SERVING</h5>
          <ul class="list-unstyled">
            <li><a href="/ancaster-web-design" class="text-decoration-none text-gray-300">Ancaster</a></li>
            <li><a href="/binbrook-web-design" class="text-decoration-none text-gray-300">Binbrook</a></li>
            <li><a href="/brantford-web-design" class="text-decoration-none text-gray-300">Brantford</a></li>
            <li><a href="/caledonia-web-design" class="text-decoration-none text-gray-300">Caledonia</a></li>
            <li><a href="/dunnville-web-design" class="text-decoration-none text-gray-300">Dunnville</a></li>
            <li><a href="/grimsby-web-design" class="text-decoration-none text-gray-300">Grimsby</a></li>
            <li><a href="/kitchener-web-design" class="text-decoration-none text-gray-300">Kitchener</a></li>
            <li><a href="/hamilton-web-design" class="text-decoration-none text-gray-300">Hamilton</a></li>
            <li><a href="/niagara-falls-web-design" class="text-decoration-none text-gray-300">Niagara Falls</a></li>
            <li><a href="/toronto-web-design" class="text-decoration-none text-gray-300">Toronto</a></li>
            <li><a href="/welland-web-design" class="text-decoration-none text-gray-300">Welland</a></li>
          </ul>
        </div>
        
        <!-- More Items -->
        <div class="col-12 col-md">
          <h5 class="text-white">MORE ITEMS</h5>
          <ul class="list-unstyled">
            <li><a href="/privacy" class="text-decoration-none text-gray-300">Privacy Policy</a></li>
            <li><a href="/terms" class="text-decoration-none text-gray-300">Terms of Service</a></li>
            <li><a href="/sitemap.xml" class="text-decoration-none text-gray-300">Sitemap</a></li>
          </ul>
        </div>
      </div>
      
      <div class="row align-items-center mt-4">
        <div class="col-12 col-md text-center text-md-left">
          <p class="mb-md-0">Copyright © {{ currentYear }} Blacktie Collaborative.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      // Add a data property for the current year
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
/* Add custom styles for the footer links */

footer {
  background: linear-gradient(130deg, #000 0%, #342c18 60%, #7a683b 100%);
}

footer li {
  
  line-height: 2; /* Adjust this value as needed */
}
</style>