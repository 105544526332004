<template>
    <span>
        <section class="py-7 py-xl-9 bg-gray-100 overflow-hidden">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-7 col-xl-6 col-xxl-5">
                        <h2 class="display-4 fw-bold text-gradient-primary text-uppercase mb-4">
                            Pricing Made Easy
                        </h2>
                    </div>
                </div>
                <ul class="nav nav-tabs" id="serviceTabs" role="tablist">
                    <li class="nav-item" role="presentation" v-for="tab in tabs" :key="tab.id">
                        <button class="nav-link" :class="{ active: tab.active }" @click="selectTab(tab.id)" :id="`${tab.id}-tab`" data-bs-toggle="tab" :data-bs-target="`#${tab.id}`" type="button" role="tab" :aria-controls="tab.id" :aria-selected="tab.active">{{ tab.title }}</button>
                    </li>
                </ul>
                <div class="row g-4 d-flex" >
                    <div class="col-md-4" v-for="plan in filteredPlans" :key="plan.title" style="margin-top: 40px;">
                        <div class="card" :class="{ 'bg-gold': plan.highlight, 'scale-up': plan.highlight, 'h-100': true }">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h5 class="card-title">{{ plan.title }}</h5>
                                    <p>{{ plan.description }}</p>
                                    <ul class="features-list">
                                        <li v-for="feature in plan.features" :key="feature">
                                            <font-awesome-icon icon="star" style="font-size: 12px;"/> {{ feature }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="price-section">
                                    <span class="price-amount">${{ plan.price }}</span>
                                    <span class="price-timeframe" v-if="plan.perMonth === true">/month</span>
                                    <span class="price-timeframe" v-else-if="plan.perHour === true">/hour</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <br><br><br><br>
            <div class="text-center">
                    <a href="/contact-us" class="btn btn-outline-gradient-primary  aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">Get Your Free Website Assessment</a>
                </div>
        </section>
    </span>
</template>

<script>
export default {
    data() {
        return {
            tabs: [
                { 
                    id: 'web-dev', 
                    title: 'Web Development & Design', 
                    active: true, 
                    plans: [
                        { 
                            title: 'Basic Website Development', 
                            price: '149', 
                            perMonth: true, 
                            description: 'For businesses that have a basic website and basic web needs. Generally less than 10 pages, and very few monthly updates needed.',
                            features: ['Generally less than 10 main pages', 'Unlimited forms & responses sent to your email', 'Unlimited images', 'Unlimited videos', '1 starter SEO optimized blog post per month', 'Locally optimized for your area'], 
                            highlight: false 
                        },
                        { 
                            title: 'Pro', 
                            price: '399', 
                            perMonth: true, 
                            features: ['Unlimited pages', 'Everything in "Basic Website"','4 high-quality, SEO optimized blog posts per month', 'Programmatic SEO optimization', 'Optimized beyond your local area', 'E-commerce integration', 'Cart and checkout integration', 'Custom code services', 'Suggested automations to implement', 'Priority customer support', 'Same day bug fixes', '96 hour small feature implementation turnaround' ], 
                            highlight: true 
                        },
                        { 
                            title: 'Max Bundle', 
                            price: '2999', 
                            perMonth: true, 
                            description: 'For businesses that need all of our services listed at any given time.',
                            features: ['Everything in all tiers for all services', 'Access to the top tier of all other services', 'Unlimited requests per month in all services', 'Priority customer support', 'Same day feature implementation turnaround', 'Weekend Support'], 
                            highlight: false 
                        }
                    ] 
                },
                { 
                    id: 'app-dev', 
                    title: 'App Development', 
                    active: false, 
                    plans: [
                        { 
                            title: 'Basic App Development', 
                            description: 'For businesses that need an app, but only something simple.',
                            price: '149', 
                            perMonth: true, 
                            features: ['Maximum 5 Tabs', 'Informational App', 'Basic auth integration', 'Basic Rewards System'], 
                            highlight: false 
                        },
                        {
                            title: 'Pro', 
                            price: '399', 
                            perMonth: true, 
                            features: ['Unlimited Pages', 'Custom Features', 'Auth integration', 'Rewards System', 'Push Notifications', 'API Integrations', 'Custom Code Services', 'Priority Customer Support', 'Same Day Submission Bug Fixes', '96 Hour Small Feature Implementation Turnaround' ], 
                            highlight: true 
                        },
                        { 
                            title: 'Max Bundle', 
                            price: '1999', 
                            description: 'For businesses that need all of our services listed at any given time.',
                            perMonth: true, 
                            features: ['Everything in all tiers for all services', 'Access to the top tier of all other services', 'Unlimited requests per month in all services', 'Priority customer support', 'Same day feature implementation turnaround', 'Weekend Support'], 
                            highlight: false 
                        }
                    ] 
                },
                { 
                    id: 'seo-opt', 
                    title: 'SEO Optimization', 
                    active: false, 
                    plans: [
                        { 
                            title: 'Basic Search Engine Optimizations', 
                            price: '149', 
                            perMonth: true, 
                            features: ['Basic SEO', 'Basic Keyword Research', 'Basic Competitor Analysis', 'Basic On-Page SEO', 'Basic Off-Page SEO', 'Google Local Listing', 'Up to 3 blog posts per month'], 
                            highlight: false 
                        },
                        {
                            title: 'Pro', 
                            price: '399', 
                            perMonth: true, 
                            features: ['Advanced SEO', 'Advanced Keyword Research', 'Advanced Competitor Analysis', 'Advanced On-Page SEO', 'Advanced Off-Page SEO', 'Google Local Listing', 'Up to 10 blog posts per month', 'Programatical SEO Integrations',
                            'Priority Customer Support', '96 Hour Small Feature Implementation Turnaround'],
                            highlight: true 
                        },
                        { 
                            title: 'Max Bundle', 
                            price: '2999', 
                            description: 'For businesses that need all of our services listed at any given time.',
                            perMonth: true, 
                            features: ['Everything in all tiers for all services', 'Access to the top tier of all other services', 'Unlimited requests per month in all services', 'Priority customer support', 'Same day feature implementation turnaround', 'Weekend Support'], 
                            highlight: false 
                        }
                    ] 
                },
                { 
                    id: 'socket-dev', 
                    title: 'Flat Rate Development', 
                    active: false, 
                    plans: [
                        { 
                            title: 'Socket Development', 
                            price: '60',
                            perMonth: false, 
                            perHour: true,
                            features: ['Socket Development', 'Socket Maintenance', 'Socket Bug Fixes', 'Socket Feature Implementation', 'Socket Consultation'], 
                            highlight: false 
                        },
                        { 
                            title: 'Discord Bot Creation', 
                            price: '40',
                            perMonth: false, 
                            perHour: true,
                            features: ['Discord Bot Creation', 'Discord Bot Maintenance', 'Discord Bot Bug Fixes', 'Discord Bot Feature Implementation', 'Discord Bot Consultation'], 
                            highlight: false 
                        },
                        { 
                            title: 'Twitch Extension Development', 
                            price: '50',
                            perMonth: false, 
                            perHour: true,
                            features: ['Twitch Extension Development', 'Twitch Extension Maintenance', 'Twitch Extension Bug Fixes', 'Twitch Extension Feature Implementation', 'Twitch Extension Consultation'], 
                            highlight: false 
                        },
                        { 
                            title: 'Chrome Extension Development', 
                            price: '35',
                            perMonth: false, 
                            perHour: true,
                            features: ['Chrome Extension Development', 'Chrome Extension Maintenance', 'Chrome Extension Bug Fixes', 'Chrome Extension Feature Implementation', 'Chrome Extension Consultation'], 
                            highlight: false 
                        }
                    ] 
                },
            ],
            selectedTab: 'web-dev'
        }
    },
    computed: {
        filteredPlans() {
            const tab = this.tabs.find(tab => tab.id === this.selectedTab);
            return tab ? tab.plans : [];
        }
    },
    methods: {
        selectTab(tabId) {
            this.selectedTab = tabId;
            this.tabs.forEach(tab => {
                tab.active = (tab.id === tabId);
            });
        }
    }
}
</script>

<style scoped>
.bg-gold {
  background-color: #e1be69;
  color: black;
}

.scale-up {
  transform: scale(1.1);
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-link.active {
  background-color: gold;
  color: black;
  border-color: gold;
}

.price-text, .price-section {
  text-align: center;
  margin-top: auto; /* Pushes the price to the bottom */
}

.price-amount {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.price-timeframe {
  font-size: 1.2rem;
  color: #666;
}

.card-title {
  color: #212529;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.features-list svg {
  margin-right: 10px;
}
</style>