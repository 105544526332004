<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/webdesign-1 copy.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            <!-- We build highly technical softwares built to make you grow -->
                            Helpers that make your browsing experience better
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                        {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Why Choose BlacktieCollab?
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where we specialize in Chrome Extension Development, creating bespoke extensions that enhance browser functionality and streamline user experiences. Our expert developers design and build custom Chrome extensions that cater to a wide range of needs, from productivity tools to advanced data analysis features.</p>

                        <p>At Blacktie Collaborative, we understand the potential that Chrome extensions have to transform everyday browsing into a highly efficient and personalized experience. Our development process involves a deep understanding of your needs, ensuring that each extension is perfectly aligned with your business goals and user requirements.</p>

                        <p>We begin by assessing your specific objectives, whether it's improving user engagement, automating tasks, or providing seamless access to your services directly from the browser. Our team then utilizes the latest web technologies to develop robust, secure, and intuitive Chrome extensions that integrate seamlessly with Google Chrome’s features.</p>

                        <p>Serving clients in <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> and across the globe, Blacktie Collaborative is committed to delivering high-quality Chrome Extension Development services. We ensure that every extension we develop adheres to Google's stringent guidelines and best practices, ensuring compatibility and performance.</p>

                        <p>Recognizing the importance of a smooth user interface and functionality, our Chrome extensions are designed to be user-friendly and highly functional, enhancing your users' interaction with your digital products and services. From custom toolbar buttons to context menus and notification alerts, our extensions are built to maximize efficiency and engagement.</p>

                        <p>If you’re looking to expand your digital offerings with custom Chrome extensions that offer utility and innovation, Blacktie Collaborative is here to help. No matter where you are, from <span v-if="city">{{ cityDisplayName }}</span> to any other location, our team is equipped to bring your vision to life.</p>

                        <p>To explore how our Chrome Extension Development services can benefit your business, click the <strong>Contact Us</strong> button below and start a conversation with our technology experts today.</p>

                    </div>
                </div>
            </div>
        </section>

        
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>
        <ConnectWithUs></ConnectWithUs>
        <Niches></Niches>
        <OurLocation></OurLocation>
        
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import OurLocation from '../components/OurLocation.vue'
import Niches from '../components/Niches.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'

export default {
  name: 'ChromeExtensionDevelopmentPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, Niches, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'Chrome Extension Development';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/chrome-extension-development';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative specializes in bespoke ${this.pageTitle.toLowerCase()}, enhancing browser functionality with custom tools for productivity, data analysis, and seamless service integration. Our expert team creates robust, secure, and user-friendly extensions tailored to your business needs.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative specializes in bespoke ${this.pageTitle.toLowerCase()}, enhancing browser functionality with custom tools for productivity, data analysis, and seamless service integration. Our expert team creates robust, secure, and user-friendly extensions tailored to your business needs.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>