<template>
    <section class="py-7 py-xl-9 position-relative">
      <div
        class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
        style="background-image: url('/media/bg-2.png'); background-position: center bottom 150px;"
      ></div>
      <div class="container">
        <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
          Why Work with Us?
        </h2>
        <div class="row justify-content-center gy-7">
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3" v-for="box in workWithUsBoxes" :key="box.id">
            <div class="card h-100 border-bottom border-primary border-bottom-10">
              <div class="card-body text-center py-7">
                <img :src="box.imgSrc" class="of-contain mb-7" width="120" height="120" :alt="box.imgAlt" />
                <h4 class="text-uppercase"><b>{{ box.title }}</b></h4>
                <p class="lead mb-0">{{ box.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'WhyWorkWithUs',
  props: {
    customWorkWithUsData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
        defaultWorkWithUsBoxes: [
        {
            id: 1,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Custom Build Solutions.png',
            imgAlt: 'Our Custom Built Solutions Have Helped Businesses Increase Online Sales by 100% or More',
            title: 'Custom Built Solutions',
            description: "We build solutions that are completely tailored to how your business works. No cookie cutter solutions. Because of this, not only do we increase your revenue, but we lower your costs as well."
        },
        {
            id: 2,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Unmatched Speeds All Over.png',
            imgAlt: 'Experience Unmatched Speed in Website Performance and Responsive Customer Service',
            title: 'Few Keep Up With Us',
            description: "We help you launch faster, we help you update faster, we're more responsive and actually care about doing things the right way, rather than the billable way."
        },
        {
            id: 3,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Responsive Websiite Development.png',
            imgAlt: 'Our Designs and Features are Not Just Market-Leading—They\'re Trendsetting',
            title: 'Responsive Website Development',
            description: 'We build everything to work just as well on phones as they do tablets and computers. We also check your analytics, and put extra effort into the devices that are most popular with your customers.'
        },
        {
            id: 4,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Integrated E-Commerce.png',
            imgAlt: 'Our integrated e-commerce solutions are designed to help you sell more',
            title: 'Integrated E-Commerce',
            description: 'Our integrated e-commerce solutions are designed to help you sell more, faster. We\'ve helped businesses increase their online sales by 100% or more, growing their revenue and customer base. Some of our best results include 10x growth! Are you next?'
        },
        {
            id: 5,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Foundational SEO.png',
            imgAlt: 'Our foundational SEO services are designed to help you rank higher in search engines',
            title: 'SEO That Reveals Niches',
            description: "We help you find the hidden niches that are easy to rank for but are highly profitable. We don't focus on trying to one-up your competitors, we focus on finding you areas you never considered growing into."
        },
        {
            id: 6,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Focused on you succeding.png',
            imgAlt: 'Our complete focus is making sure you succeed',
            title: 'Focused on You Succeeding',
            description: 'Our complete focus is making sure you succeed. We do not accept all clients and we only work with clients we know we can help. We\'re focused on bringing you traffic, customers, sales, and revenue. Our pricing structures are built around that.'
        }
      ]
    };
  },
  computed: {
    workWithUsBoxes() {
      // If customWorkWithUsData prop is provided and not empty, use it. Otherwise, use default data.
      return this.customWorkWithUsData.length > 0 ? this.customWorkWithUsData : this.defaultWorkWithUsBoxes;
    }
  }
};
</script>

<!-- <template>
    <section class="py-7 py-xl-9 position-relative">
      <div
        class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
        style="background-image: url('/media/bg-2.png'); background-position: center bottom 150px;"
      ></div>
      <div class="container">
        <h2
          class="display-4 fw-bold text-center  text-uppercase mb-7 mb-xl-9"
        >
          Why Work with Us?
        </h2>
        <div class="row justify-content-center gy-7">
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3">
            <div
              class="card h-100 border-bottom border-primary border-bottom-10"
            >
              <div class="card-body text-center py-7">
                <img
                  src="https://cdn.blacktiecollab.com/blacktiecollab.com/Custom Build Solutions.png"
                  class="of-contain mb-7"
                  width="120"
                  height="120"
                  alt="Our Custom Built Solutions Have Helped Businesses Increase Online Sales by 100% or More"
                />
                <h4 class="text-uppercase"><b>Custom Built Solutions</b></h4>
                <p class="lead mb-0">
                  Our custom-built solutions have helped businesses increase online sales by 100% or more, growing their revenue and customer base. Some of our best results include 10x growth! Are you next?
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3">
            <div
              class="card h-100 border-bottom border-primary border-bottom-10"
            >
              <div class="card-body text-center py-7">
                <img
                  src="https://cdn.blacktiecollab.com/blacktiecollab.com/Unmatched Speeds All Over.png"
                  class="of-contain mb-7"
                  width="120"
                  height="120"
                  alt="Experience Unmatched Speed in Website Performance and Responsive Customer Service"
                />.
                <h4 class="text-uppercase"><b>Unmatched Speeds All Over</b></h4>
                <p class="lead mb-0">
                  Experience unmatched speed—both in website performance and our responsive customer service, ensuring your business never misses a beat. We also ship faster than our competitors, getting your website working for you quicker.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3">
            <div
              class="card h-100 border-bottom border-primary border-bottom-10"
            >
              <div class="card-body text-center py-7">
                <img
                  src="https://cdn.blacktiecollab.com/blacktiecollab.com/Responsive Websiite Development.png"
                  class="of-contain mb-7"
                  width="120"
                  height="120"
                  alt="Our Designs and Features are Not Just Market-Leading—They're Trendsetting"
                />
                <h4 class="text-uppercase"><b>Responsive Website Development</b></h4>
                <p class="lead mb-0">
                  Our designs and features are not just market--leading—they're trendsetting, ensuring you're always ahead of the curve and competition. Don't settle for less. Our designs work on all devices and screen sizes and keep the customer engaged.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3">
            <div
              class="card h-100 border-bottom border-primary border-bottom-10"
            >
              <div class="card-body text-center py-7">
                <img
                  src="https://cdn.blacktiecollab.com/blacktiecollab.com/Integrated E-Commerce.png"
                  class="of-contain mb-7"
                  width="120"
                  height="120"
                  alt="Our integrated e-commerce solutions are designed to help you sell more"
                />
                <h4 class="text-uppercase"><b>Integrated E-Commerce</b></h4>
                <p class="lead mb-0">
                  Our integrated e-commerce solutions are designed to help you sell more, faster. We've helped businesses increase their online sales by 100% or more, growing their revenue and customer base. Some of our best results include 10x growth! Are you next?
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3">
            <div
              class="card h-100 border-bottom border-primary border-bottom-10"
            >
              <div class="card-body text-center py-7">
                <img
                  src="https://cdn.blacktiecollab.com/blacktiecollab.com/Foundational SEO.png"
                  class="of-contain mb-7"
                  width="120"
                  height="120"
                  alt="Our foundational SEO services are designed to help you rank higher in search engines"
                />
                <h4 class="text-uppercase"><b>Foundational SEO</b></h4>
                <p class="lead mb-0">
                  Our foundational SEO services are designed to help you rank higher in search engines, increasing your visibility and driving more traffic to your website. We've helped businesses increase their online sales by 100% or more, growing their revenue and customer base. Some of our best results include 10x growth! Are you next?
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3">
            <div
              class="card h-100 border-bottom border-primary border-bottom-10"
            >
              <div class="card-body text-center py-7">
                <img
                  src="https://cdn.blacktiecollab.com/blacktiecollab.com/Focused on you succeding.png"
                  class="of-contain mb-7"
                  width="120"
                  height="120"
                  alt="Our complete focus is making sure you succeed"
                />
                <h4 class="text-uppercase"><b>Focused on you succeeding</b></h4>
                <p class="lead mb-0">
                  Our complete focus is making sure you succeed. We do not accept all clients and we only work with clients we know we can help. We're focused on bringing you traffic, customers, sales, and revenue. We're not focused on the fluff.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template> -->