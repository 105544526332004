<template>
    <div class="single-post-page">
      <div v-if="loading" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div v-if="loading === false">
        <section class="bg-cover" style="background-image: url('/media/bg-single-post.jpg');">
            <div class="container py-7 py-xl-9">
            <div class="row">
                <div class="col-12">
                <h1 class="display-3 text-gradient-primary text-uppercase fw-bold mb-3">{{ post.title.rendered }}</h1>
                <p class="text-gray-800">{{ post.date | formatDate }}</p>
                <p class="text-gray-400">Written by Shane Heyworth</p>
                </div>
            </div>
            </div>
        </section>
    
        <section class="py-7 py-xl-9 bg-white">
            <div class="container">
                
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                <img v-if="post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]" :src="post._embedded['wp:featuredmedia'][0].source_url" class="img-fluid mb-5" />
                <div v-html="post.content.rendered"></div>
                </div>
            </div>
            <div class="text-center mt-5">
                    <router-link to="/blog" class="btn btn-primary">Back to Blog</router-link>
                </div>
            </div>
        </section>
        </div>
        <ConnectWithUs></ConnectWithUs>
        <OurLocation></OurLocation>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
    import ConnectWithUs from '../components/ConnectWithUs.vue';
    import OurLocation from '../components/OurLocation.vue';

    export default {
    name: "SingleBlogPost",
    data() {
        return {
            post: null,
            loading: false, 
        };
    },
    components: {
        ConnectWithUs,
        OurLocation,
    },
    filters: {
        formatDate(value) {
        const date = new Date(value);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
    },
    async created() {
        this.loading = true;
        const slug = this.$route.params.slug;
        const response = await axios.get(`https://wii.dnw.mybluehost.me/website_c0adc160/wp-json/wp/v2/posts?slug=${slug}&_embed`);
        if (response.data.length > 0) {
        this.post = response.data[0];
        this.loading = false;
        } else {
        // Handle the case where the post is not found
        this.$router.push({ name: 'blog' }); // Assuming 'BlogPage' is the name of your blog listing page route
        }
    },
    metaInfo() {
        if (this.post) {
        const featuredImage = this.post._embedded && this.post._embedded['wp:featuredmedia'] && this.post._embedded['wp:featuredmedia'][0] ? this.post._embedded['wp:featuredmedia'][0].source_url : '';
        return {
            title: this.post.title.rendered + ' - Blacktie Collaborative',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.post.excerpt.rendered.replace(/<[^>]+>/g, ''), // Strip HTML tags
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.post.title.rendered,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.post.excerpt.rendered.replace(/<[^>]+>/g, ''), // Strip HTML tags
                },
                {
                    vmid: 'og:image',
                    property: 'og:image',
                    content: featuredImage,
                },
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: `https://blacktiecollab.com/blog/${this.post.slug}`,
                },
            ],
        };
        }
        // Default metaInfo if post is not loaded yet
        return {
        title: 'Loading Post - Blacktie Collaborative',
        meta: [
            {
            vmid: 'description',
            name: 'description',
            content: 'Loading content...',
            },
        ],
        };
    },
    };
    </script>
  
  <style scoped>
  /* Add any additional styling here, following the theme from BlogPage.vue */
  .single-post-page .bg-cover {
    background-size: cover;
    background-position: center;
  }
  </style>