<template>
  <div>
    <span v-if="brand">
      <section class="py-7 py-xl-9 bg-white" >
        <div class="container">
          <div class="row align-items-center gy-7 justify-content-between">
            <div class="col-12 col-lg-6 order-2" data-aos="fade-left" >
              <img :src="image" class="img-fluid" style="margin-bottom: 20px;" v-for="(image, key) of brand.images" :key="key"/>
            </div>
            <div class="col-12 col-lg-6 col-xxl-5 order-1" data-aos="fade-right">
              <h2 class="h1 fw-bold text-gradient-primary text-uppercase mb-3">
                {{ brand.name }}
              </h2>
              <a v-if="brand.linkUrl" :href="brand.linkUrl" target="_blank" class="btn btn-primary">Visit their Site</a>
              <hr>
              <span v-if="yearsTogether">
                <p class="small text-secondary text-uppercase mb-0">
                  Years we've worked together:
                </p>
                <p class="fw-bold lead mb-0">
                  {{ yearsTogether }}
                </p>
              </span>
              <span v-if="brand.startDate">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  Started the project on:
                </p>
                <p class="fw-bold lead mb-0">
                  {{ brand.startDate }}
                </p>
              </span>
              
              <span v-if="brand.startDate">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  Launched the project on:
                </p>
                <p class="fw-bold lead mb-0">
                  {{ brand.launchDate }}
                </p>
              </span>
              
              <span v-if="brand.locatedIn">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  Located in:
                </p>
                <p class="fw-bold lead mb-0">
                  {{ brand.locatedIn }}
                </p>
              </span>
              <span v-if="brand.whatWeBuilt">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  What we built for {{ brand.name}}:
                </p>
                <p class="lead mb-0">
                  {{ brand.whatWeBuilt }}
                </p>
              </span>
              <span v-if="brand.futurePlans">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  Where we're taking {{  brand.name }} in the future:
                </p>
                <p class="lead mb-0">
                  {{ brand.futurePlans }}
                </p>
              </span>
              <span v-if="brand.plaintextFeatures">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  User-Facing Features
                </p>
                <p class="lead mb-0">
                  {{ brand.plaintextFeatures }}
                </p>
              </span>
              <span v-if="brand.technicalFeatures">
                <hr />
                <p class="small text-secondary text-uppercase mb-0">
                  Technical Features
                </p>
                <p class="lead mb-0">
                  {{ brand.technicalFeatures }}
                </p>
              </span>

            </div>
          </div>
        </div>
      </section>

      <section class="py-7 py-xl-9 position-relative">
        <div
          class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
          style="background-image: url('/media/bg-4.png');"
        ></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-2"></div>
            <div class="col-8 col-xxl-10 text-center">
              <img
                src="/media/quote.png"
                width="100"
                height="100"
                class="of-contain mb-4"
              />
              <h6 class="h2 fw-bold mb-6">
                {{ brand.quoteText }}
              </h6>
              <div class="mb-1">
                <font-awesome-icon icon="star" class="text-warning" />
                <font-awesome-icon icon="star" class="text-warning" />
                <font-awesome-icon icon="star" class="text-warning" />
                <font-awesome-icon icon="star" class="text-warning" />
                <font-awesome-icon icon="star" class="text-warning" />
              </div>
              <h5 class="mb-1 fw-bold">{{ brand.quoteAuthor }}</h5>
              <p class="small text-secondary text-uppercase mb-0">
                {{ brand.quotePosition }}
              </p>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </section>
      <WhyWorkWithUs></WhyWorkWithUs>
      <BrandConnectWithUs></BrandConnectWithUs>
    </span>
    <div v-else>
      <!-- This will be displayed while brand is null, you can customize it as needed -->
      Loading brand information...
    </div>
  </div>
</template>

<script>
import WhyWorkWithUs from '../components/WhyWorkWithUs.vue';
import BrandConnectWithUs from '../components/BrandConnectWithUs.vue';
const brands = {
  'slo-pitch-national': {
    name: 'Slo-Pitch National',
    linkUrl: 'https://slo-pitch.com/',
    startDate: '2019-06-01',
    launchDate: '2020-01-01',
    locatedIn: 'Brampton, Ontario, Canada',
    whatWeBuilt: `We built them a fully integrated frontend website with a custom member registration system that fits their userbase. As the national governing body for Slo-Pitch in Canada, they needed a system that could handle the volume of registrations they receive each year, up to 200k users.`,
    futurePlans: `We continue to expand our offerings with Slo-Pitch National, not only providing them with additional tools and features that we have a vested interest in, but continuing to expand on our products we've built for them under contract. Our partnership with Slo-Pitch National is a perfect example of how we like to grow with our clients and help them adapt, even if it's outside their budget, if we see a market fit that we can help with.`,
    plaintextFeatures: 'User Management System, Waiver System, Custom Registration System, Umpire Management System, Event Management, Roster Management, Team Management, League Management, Tournament Management, Custom Reporting, Automated Backend Systems, Custom Admin Panel',
    technicalFeatures: 'Redis Job Queuing, Shopify App Development, Vue.js Frontend, Node.js Backend, MySQL Database, Chatbot Automation Flows',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/slo-pitch-national/CleanShot 2024-04-17 at 17.01.41.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/slo-pitch-national/CleanShot 2024-04-17 at 17.05.29.png'],
    imageUrl: '',
    quoteText: 'Working with Blacktie has been a great experience. They are always available to help us with any issues we have, and they are always looking for ways to improve our systems. We are very happy with the work they have done for us. We look forward to working with them in the future.',
    quoteAuthor: 'Trish Harrow',
    quotePosition: 'CEO of Slo-Pitch National',
  },
  // 'game-on-mobile': {
  //   name: 'Game On Mobile',
  //   linkUrl: 'https://gameonmobile.com/',
  //   startDate: '2020-06-01',
  //   launchDate: '2021-01-01',
  //   whatWeBuilt: 'We built Game On Mobile a fully integrated sports management engine, with incredibly robust features that provide league and tournament management, team management, registration and stat tracking for small to medium size organizations. With unique features like custom bracket generation, schedule generation, Stripe integration with an easy way to set up registration for leagues and tournaments, and a custom admin panel that allows for easy management of all aspects of the system.',
  //   futurePlans: '',
  //   plaintextFeatures: 'Event Management System, Score Reporting, Custom Standings Configurations, Bracket Generator, Baseball & Hockey Stat Tracking, Division Management, Team Management, Roster Management, Registration Management',
  //   technicalFeatures: 'Redis Job Queuing, Complex Queries, Vue.js Frontend, Node.js Backend, Postgres Database, Custom Admin Panel, Stripe API Integration',
  //   logoUrl: '',
  //   imageUrl: '',
  //   quoteText: 'Blacktie has been incredible to work with. Not only has Blacktie been incredibly responsive, productive, and efficient, but they were able to visualize what we wanted done and make it come to life. I would absolutely recommend Blacktie to anyone looking to make an impact online. I truly admire and appreciate the commitment Blacktie brought to every project that they built for us.',
  //   quoteAuthor: 'Kyle Hodges',
  //   quotePosition: 'Owner, GameOnMobile.com',
  // },
  'elevate-the-globe': {
    name: 'Elevate The Globe',
    linkUrl: 'https://elevatetheglobe.com/',
    startDate: '2016-04-01',
    launchDate: '2016-07-01',
    whatWeBuilt: 'We built Elevate The Globe an incredible ecommerce system, which has helped them generate over $1M in revenue, while also consulting and providing our knowledge on how to effectively optimize these systems to generate both traffic and conversions. We have also built them a custom admin panel that allows them to manage their products, orders, and customers with ease.',
    futurePlans: '',
    plaintextFeatures: 'Event Management, Course Management, Course Creation, Subscription Management, Custom Membership Dashboard, Fully Custom Course Layouts, Custom Admin Panel, Lead Pages, Custom Sales Pages, Custom Checkout Flows',
    technicalFeatures: 'Vue.js Frontend, Node.js Backend, MySql Database, Stripe Integration, Convertkit Integration, SendGrid Integration, Mailchimp Integration, Custom Sale Flows, Custom Admin Panel',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/elevate-the-globe/CleanShot 2024-04-17 at 17.07.37.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/elevate-the-globe/CleanShot 2024-04-17 at 17.08.59.png'],
    imageUrl: '',
    quoteText: 'Working with Blacktie has been an incredible experience! I am so pleased with my decision to work with them and it has made my life so much easier already! I already have more traffic, love my website and have the support I was lacking before! Their developers are very talented, responsive, and creative. My traffic has already increased and I am launching a Meditation course soon that I am confident will bring in increased revenues with the technology Blacktie created!',
    quoteAuthor: 'Britt Deanda',
    quotePosition: 'Co-Founder, Elevate The Globe, Intune Kundalini & More',
  },
  'teamgear-ca': {
    name: 'TeamGear.ca',
    linkUrl: 'https://teamgear.ca/',
    startDate: '2022-03-01',
    launchDate: '2022-04-01',
    whatWeBuilt: 'While not fully custom this time, we built TeamGear a Shopify store that is fully automated and helps generate warm leads for them with minimal effort. We built this with our foundational SEO practices to generate consistent traffic and conversions for them. Within the first year TeamGear was able to generate over $100k in revenue with minimal effort pre-sale. These processes are never before done in the industry they are working in, and we are proud to have been able to help them achieve this.',
    futurePlans: 'We continue to work with TeamGear to produce new layouts and designs for their store, as well as help them with their marketing efforts. We are looking for opportunities to subsidize features that creates them more revenue, and we are excited to see where this partnership goes.',
    plaintextFeatures: 'Custom Shopify Theme, Custom Page Layouts, Conversion Techniques, Onboarding Automation, Custom Product Pages, Custom Collection Pages, Custom Checkout Flows',
    technicalFeatures: '',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/teamgear-ca/CleanShot 2024-04-17 at 17.12.29.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/teamgear-ca/CleanShot 2024-04-17 at 17.13.38.png'],
    imageUrl: '',
    quoteText: `We are absolutely blown away by the efforts of Blacktie. They have been able to take our store to the next level and we are so excited to see where this goes. We have been able to generate more revenue in the first year than we have in the last 5 years combined. We are so excited to see where this goes.`,
    quoteAuthor: 'Madi Smith',
    quotePosition: 'Co-Owner, TeamGear',
  },
  'showzone': {
    name: 'Showzone',
    linkUrl: 'https://showzone.gg/',
    startDate: '2022-03-01',
    launchDate: '2019-03-01',
    whatWeBuilt: `With Showzone, we came on later than their launch when they were already growing, but we have brought them to the next level. We spent a ton of time optimizing every page, ensuring that we're both explaining their complex features, and making them as easy to use as possible, as well as expanding their technology stack, including things like Events with Matchmaking, Live Drafts, and more. `,
    futurePlans: `We're excited to be launching Showzone Savant soon, which is a statistical platform based on your gameplay, as well as continuing to expand on the features, simplify them based on user feedback, and continue to grow the platform. We are excited to see where this goes, and we are excited to be a part of the journey.`,
    plaintextFeatures: 'Player Database, Complex Table Sorting/Filtering, User Profiles, Push Notifications, Web Notifications',
    technicalFeatures: 'Table Optimizations, Push Notifications, Web Notifications, Next.js Frontend with React, Node.js Backend, Postgres Database',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/showzone/CleanShot 2024-04-17 at 17.14.52.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/showzone/CleanShot 2024-04-17 at 17.15.44.png'],
    imageUrl: '',
    quoteText: '',
    quoteAuthor: '',
    quotePosition: '',
  },
  'intune-kundalini': {
    name: 'Intune Kundalini',
    linkUrl: 'https://intunekundalini.com/',
    startDate: '2022-01-01',
    launchDate: '2022-03-01',
    whatWeBuilt: 'We built a custom frontend website, on top of the Uscreen platform, that allows for a fully integrated course management system, with custom course layouts, custom sales pages, and custom checkout flows.',
    futurePlans: 'We are working with Intune Kundalini to create an incredible SEO foundation that is focused on bringing them consistent traffic and conversions. We are aiming to build them out something that provides them traffic for years to come without additional effort or investment.',
    plaintextFeatures: '',
    technicalFeatures: '',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/intune-kundalini/CleanShot 2024-04-17 at 17.16.46.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/intune-kundalini/CleanShot 2024-04-17 at 17.17.13.png'],
    imageUrl: '',
    quoteText: 'Working with Blacktie has been an incredible experience! I am so pleased with my decision to work with them and it has made my life so much easier already! I already have more traffic, love my website and have the support I was lacking before! Their developers are very talented, responsive, and creative. My traffic has already increased and I am launching a Meditation course soon that I am confident will bring in increased revenues with the technology Blacktie created!',
    quoteAuthor: 'Britt Deanda',
    quotePosition: 'Co-Founder, Elevate The Globe, Intune Kundalini & More',
  },
  'henning-park': {
    name: 'Henning Park',
    linkUrl: 'https://henningpark.com/',
    startDate: '2019-05-01',
    launchDate: '2019-06-01',
    whatWeBuilt: 'Henning Park is both a simple website, and a huge charitable focus for us. We provide our services to Henning Park completely free, as they are a non-profit that allows kids to play for free. We built them a simple website that allows them to showcase their park, and integrate their standings and schedules for adult leagues, as well as a platform to advertise the events at the park.',
    futurePlans: '',
    plaintextFeatures: '',
    technicalFeatures: '',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/henning-park/CleanShot 2024-04-17 at 17.18.29.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/henning-park/CleanShot 2024-04-17 at 17.19.15.png'],
    imageUrl: '',
    quoteText: `We chose Blacktie to design our website for Henning Park, and they delivered exceptional results. Their team was not only creative but also incredibly responsive to our needs, ensuring our vision for the park was beautifully and functionally represented online. The website now serves as a welcoming gateway for our visitors, thanks to Blacktie's commitment to excellence and attention to detail. We highly recommend their services to anyone looking to elevate their online presence.`,
    quoteAuthor: 'John Uildersma',
    quotePosition: 'President of Henning Park',
  },
  'playslopitch': {
    name: 'PlaySloPitch.com',
    linkUrl: 'https://playslopitch.com',
    startDate: '2023-10-01',
    launchDate: '2024-04-01',
    whatWeBuilt: 'Play Slo-Pitch is a subsidiary of Slo-Pitch National, so we naturally took the lead when this idea was presented to us. Providing them a fully custom content management system, as well as a frontend website and iOS/Android app, we have been able to provide them with a fully integrated system that allows them to manage their leagues, tournaments, and events with ease.',
    futurePlans: 'We intend to continue to develop features for the app, creating better clarity on the website and providing a fast and easy experience for their userbase.',
    plaintextFeatures: 'Frontend Website, Custom App Development, Shopify Integration',
    technicalFeatures: 'Next.js Frontend with a custom CMS, iOS/Android App Development, Custom Admin Panel, Custom Reporting, Automated Backend Systems, Custom Admin Panel, Custom Admin Panel',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/playslopitch/CleanShot 2024-04-17 at 17.20.06.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/playslopitch/CleanShot 2024-04-17 at 17.20.48.png'],
    imageUrl: '',
    quoteText: 'Working with Blacktie has been a great experience. They are always available to help us with any issues we have, and they are always looking for ways to improve our systems. We are very happy with the work they have done for us. We look forward to working with them in the future.',
    quoteAuthor: 'Trish Harrow',
    quotePosition: 'CEO of Slo-Pitch National',
  },
  'dr-tony-ortega': {
    name: 'Dr. Tony Ortega',
    linkUrl: 'https://drtonyortega.com',
    startDate: '2017-07-01',
    launchDate: '2017-09-01',
    whatWeBuilt: '',
    futurePlans: '',
    plaintextFeatures: '',
    technicalFeatures: '',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/drtonyortega/CleanShot 2024-04-17 at 17.21.35.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/drtonyortega/CleanShot 2024-04-17 at 17.22.53.png'],
    imageUrl: '',
    quoteText: '',
    quoteAuthor: '',
    quotePosition: '',
  },
  'lindsey-legrande': {
    name: 'Lindsey Legrande',
    linkUrl: 'https://lindseylegrande.com',
    startDate: '2022-08-01',
    launchDate: '2022-10-01',
    whatWeBuilt: '',
    futurePlans: '',
    plaintextFeatures: '',
    technicalFeatures: '',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/lifestylebylindsey/CleanShot 2024-04-17 at 17.25.32.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/lifestylebylindsey/CleanShot 2024-04-17 at 17.27.00.png'],
    imageUrl: '',
    quoteText: '',
    quoteAuthor: '',
    quotePosition: '',
  },
  'cspl': {
    name: 'CSPL',
    linkUrl: 'https://cspl.ca',
    startDate: '2021-03-01',
    launchDate: '2021-04-01',
    whatWeBuilt: '',
    futurePlans: '',
    plaintextFeatures: '',
    technicalFeatures: '',
    logoUrl: '',
    images: ['https://cdn.blacktiecollab.com/blacktiecollab.com/brands/cspl/CleanShot 2024-04-17 at 17.29.22.png', 'https://cdn.blacktiecollab.com/blacktiecollab.com/brands/cspl/CleanShot 2024-04-17 at 17.30.07.png'],
    imageUrl: '',
    quoteText: '',
    quoteAuthor: '',
    quotePosition: '',
  },

}
export default {
  name: "BrandPage",
  data() {
    return {
      brand: null, // This will hold our brand data
    };
  },
  components: {
    WhyWorkWithUs,
    BrandConnectWithUs
  },
  watch: {
    // Watch for changes in $route.params.slug
    '$route.params.slug': {
      immediate: true,
      handler(newValue) {
        this.loadBrandData(newValue);
      }
    }
  },
  // Example brand data object
  computed: {
    // Computed property to calculate years together dynamically
    yearsTogether() {
      if (this.brand && this.brand.startDate) {
        const startDate = new Date(this.brand.startDate);
        const currentDate = new Date();
        const yearsDifference = currentDate.getFullYear() - startDate.getFullYear();
        const monthsDifference = currentDate.getMonth() - startDate.getMonth();
        if (monthsDifference < 0 || (monthsDifference === 0 && currentDate.getDate() < startDate.getDate())) {
          return yearsDifference - 1; // Adjust for not yet reaching the anniversary this year
        }
        return yearsDifference;
      }
      return ''; // Return an empty string if no start date is available
    }
  },
  methods: {
    loadBrandData(brandName) {
      console.log('brandName', brandName);
      if (brands[brandName]) {
        this.brand = brands[brandName];
      } else {
        // Optionally, redirect to home page if brand not found
        // this.$router.push('/');
      }
    }
  },
  created() {
    this.loadBrandData(this.$route.params.slug);
  },
  metaInfo() {
    const metaTags = [];

    // Conditionally add title
    if (this.brand && this.brand.name) {
      metaTags.push({
        key: 'og:title',
        property: 'og:title',
        content: `${this.brand.name} - Your Site Name`
      });
    }

    // Conditionally add description
    if (this.brand && this.brand.whatWeBuilt) {
      metaTags.push({
        key: 'description',
        name: 'description',
        content: this.brand.whatWeBuilt
      },
      {
        key: 'og:description',
        property: 'og:description',
        content: this.brand.whatWeBuilt
      });
    }

    // Conditionally add image
    if (this.brand && this.brand.imageUrl) {
      metaTags.push({
        key: 'og:image',
        property: 'og:image',
        content: this.brand.imageUrl
      });
    }

    // Generate the dynamic canonical link
    const canonicalLink = this.brand
      ? `https://blacktiecollab.com/brand/${this.$route.params.slug}/`
      : 'https://blacktiecollab.com/brand';

    // Return the dynamic metaInfo, falling back to index.html defaults when not provided
    return {
      title: this.brand ? `${this.brand.name} - Blacktie Collaborative - Web, App & SEO` : 'Websites Without Limitation - Blacktie Collaborative - Web, App & SEO',
      meta: [
        ...metaTags,
        {
          rel: 'canonical',
          href: canonicalLink
        }
      ]
    };
  }
};
</script>