<template>
    <b-navbar toggleable="xl" type="custom">
        <div class="container d-flex justify-content-center">
          <b-navbar-toggle target="nav-collapse">
            <template>
              <font-awesome-icon icon="bars" /> <span style="text-transform: uppercase; font-weight: 700;">Menu</span>
            </template>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto mr-auto">
              <b-nav-item to="/" exact><span><font-awesome-icon icon="home" class="mr-1" /> Home</span></b-nav-item>
              <b-nav-item to="/our-process" ><span><font-awesome-icon icon="server" class="mr-1" /> Our Process</span></b-nav-item>
              <b-nav-item-dropdown right-aligned>
                <template #button-content>
                  <span><font-awesome-icon icon="star" class="mr-1" /> Our Niches</span>
                </template>
                <b-dropdown-item to="/sports">Sports</b-dropdown-item>
                <b-dropdown-item to="/lifestyle">Lifestyle</b-dropdown-item>
     
              </b-nav-item-dropdown>
              <b-nav-item-dropdown right-aligned>
                <template #button-content>
                  <span><font-awesome-icon icon="star" class="mr-1" /> Our Services</span>
                </template>
                <b-dropdown-item to="/web-design">Web Design</b-dropdown-item>
                <b-dropdown-item to="/web-development">Web Development</b-dropdown-item>
                <b-dropdown-item to="/app-development">App Development</b-dropdown-item>
                <b-dropdown-item to="/seo-optimization">SEO Optimization</b-dropdown-item>
                <b-dropdown-item to="/growth-strategizing">Growth Strategizing</b-dropdown-item>
                <b-dropdown-item to="/discord-bot-development">Discord Bot Development</b-dropdown-item>
                <b-dropdown-item to="/twitch-extension-development">Twitch Extension Development</b-dropdown-item>
                <b-dropdown-item to="/chrome-extension-development">Chrome Extension Development</b-dropdown-item>
                <b-dropdown-item to="/socket-development">Socket Development</b-dropdown-item>
              </b-nav-item-dropdown>
              <!-- <b-nav-item-dropdown right-aligned>
                <template #button-content>
                  <span><font-awesome-icon icon="star" class="mr-1" /> White Label</span>
                </template>
                <b-dropdown-item to="/sports-registration-system">Sports Registration</b-dropdown-item>
                <b-dropdown-item to="/score-reporting-system">Score Reporting</b-dropdown-item>
                <b-dropdown-item to="/referee-management-system">Referee Management</b-dropdown-item>
                <b-dropdown-item to="/streaming-membership-system">Streaming Membership</b-dropdown-item>
                <b-dropdown-item to="/restaurant-ordering">Restaurant Ordering</b-dropdown-item>
              </b-nav-item-dropdown> -->

              <b-nav-item-dropdown right-aligned>
                <template #button-content>
                  <span><font-awesome-icon icon="star" class="mr-1" /> Featured Clients</span>
                </template>
                <b-dropdown-item to="/brand/slo-pitch-national">Slo-Pitch National</b-dropdown-item>
                <!-- <b-dropdown-item to="/brand/game-on-mobile">Game On Mobile</b-dropdown-item> -->
                <b-dropdown-item to="/brand/elevate-the-globe">Elevate The Globe</b-dropdown-item>
                <b-dropdown-item to="/brand/teamgear-ca">TeamGear Canada</b-dropdown-item>
                <b-dropdown-item to="/brand/showzone">Showzone</b-dropdown-item>
                <b-dropdown-item to="/brand/intune-kundalini">Intune Kundalini</b-dropdown-item>
                <b-dropdown-item to="/brand/henning-park">Henning Park</b-dropdown-item>
                <b-dropdown-item to="/brand/playslopitch">Play Slo-Pitch</b-dropdown-item>
                <b-dropdown-item to="/brand/dr-tony-ortega">Dr. Tony Ortega</b-dropdown-item>
                <b-dropdown-item to="/brand/lindsey-legrande">Lindsey LeGrande</b-dropdown-item>
                <b-dropdown-item to="/brand/cspl">CSPL</b-dropdown-item>
     
              </b-nav-item-dropdown>
              <!-- <b-nav-item to="/projects"><span><font-awesome-icon icon="lightbulb" class="mr-1" /> Creative</span></b-nav-item> -->
              <b-nav-item to="/blog" exact><span><font-awesome-icon icon="home" class="mr-1" /> Blog</span></b-nav-item>
              <b-nav-item to="/free-site-assessment"><span><font-awesome-icon icon="recycle" class="mr-1" /> Free Site Assessment</span></b-nav-item>
              <!-- <b-nav-item to="/contact-us"><span><font-awesome-icon icon="recycle" class="mr-1" /> Connect With Us</span></b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>    
</template>

<script>

export default {
  name: 'Navigation',
  
}
</script>
