<template>
    <span>
      <section
        class="py-7 py-xl-9 bg-cover"
        style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/bg-535.png');"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                Our Process
              </p>
              <h1
                class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                data-aos="fade-up"
              >
                How We Bring Your Dream to Life
              </h1>
            </div>
          </div>
        </div>
        
        <!-- request your site assessment -->
        <!-- review your site assessment, approve, moves to production -->
        <!-- we build your site -->
      </section>
      <!-- <div class="row">
        <div class="col-md-12">
            
        </div>
      </div> -->
      <div class="margin-box">
        <!-- <img src="#" width="100%" alt=""> -->
        <ul class="styled-list">
          <li>            
              <span class="number-box">1</span>
              <span class="list-content">
                Click the button below to fill out our request for assessment form. We will review it in 24 hours with a real human reviewing your website, not some AI formula built to tell you your site sucks.
              </span>
          </li>
        </ul>
      </div>
      <div class="text-center">
        <a href="/free-site-assessment"  class="btn btn-outline-gradient-primary  aos-init aos-animate" >Get Your Free Site Assessment</a>
      </div>
      <div class="margin-box">
        <ul class="styled-list">
          <li>
              <span class="number-box">2</span>
              <span class="list-content">When you've received your Site Assessment, if you like what you see, click the Let's Get Started button to get us working on your digital preview, where we expand on our vision and bring our plans to life. In the meantime we will send you an email scheduling a call to share your thoughts.</span>
          </li>
        </ul>
      </div>
      
      <div class="margin-box">
        <ul class="styled-list">
          <li>
            <span class="number-box">3</span>
            <span class="list-content">We connect via phone to scope out your goals and plans. In the meantime, we're behind the scenes building out your website to get it ready to be launched within 4 weeks.</span>
          </li>
        </ul>
      </div>
      <div class="margin-box">
        <ul class="styled-list">
          <li>
            <span class="number-box">4</span>
            <span class="list-content">Finalizing and Quality Control - about 3 weeks into the process, we do quality control to ensure you're happy with where we've headed, clean up any issues/concerns you have, and get ready for launch.</span>
          </li>
        </ul>
      </div>
      <div class="margin-box">
        <ul class="styled-list">
          <li>
            <span class="number-box">5</span>
            <span class="list-content"><b>Time to launch! 99.6% of our clients sites launch within 4 weeks.</b></span>
          </li>
        </ul>
      </div>
          
      <LoveFromOurClients></LoveFromOurClients>  
      <ConnectWithUs></ConnectWithUs>
      <OurLocation></OurLocation>
    </span>
    
</template>

<script>
import LoveFromOurClients from "@/components/LoveFromOurClients.vue";
import ConnectWithUs from "../components/ConnectWithUs.vue";
import OurLocation from "../components/OurLocation.vue";

export default {
  name: 'Navigation',
  components: { LoveFromOurClients, ConnectWithUs, OurLocation },
}
</script>

<style scoped>
.margin-box {
  max-width: 600px;
  margin: 0 auto;
}


.styled-list {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.styled-list li {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px; /* Adjust spacing between items as needed */
}

.number-box {
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  background-color: #fff;
  border: 5px solid #e7c56d;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 40px;
  color:#e7c56d;
  margin-right: 20px; /* Adjust spacing between number and text as needed */
}

.list-content {
  flex: 1;
}
</style>
