<template>
    <section class="py-7 py-xl-9 bg-gray-100 overflow-hidden">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-7 col-xl-6 col-xxl-5">
            <h2
              class="display-4 fw-bold text-gradient-primary text-uppercase mb-4"
            >
                {{ title }}
            </h2>
            <p class="text-secondary mb-4">
                {{ description }}
            </p>
            <ContactForm></ContactForm>
          </div>
         
        </div>
      </div>
    </section>
</template>

<script>
import ContactForm from "./ContactForm.vue";
export default {
  name: "ConnectWithUs",
  components: { ContactForm },
  props: {
    title: {
      type: String,
      default: "Start your free website assessment!"
    },
    description: {
      type: String,
      default: ""
    }
  }
};

</script>